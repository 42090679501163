@mixin banner-btn-mixin {
  max-width: 400px;
  min-width: 250px;
}

@mixin banner-background-mixin {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

