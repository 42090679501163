
@use "src/css/mixins/paddingMixin" as paddingMixin;

@media (min-width: (660px + 1px)) {
  .app-banner-content {
    width: calc(100% - 364px);
  }
}

@media (max-width: 660px) and (min-width: (500px + 1px)) {
  .app-banner-content {
    width: calc(100% - 255px);
  }
}

@media (max-width: 500px) and (min-width: (435px + 1px)) {
  .app-banner-content {
    width: calc(100% - 200px);
  }
}

@media (min-width: (435px + 1px)) {
  .pl-md-gt-435 {
    @include paddingMixin.padding-mixin('left', 'md');
  }

  .justify-center-gt-435 {
    justify-content: space-between;
  }
}

@media (max-width: 435px) {
  .justify-center-lt-435 {
    justify-content: center;
  }
}
