
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/textMixin" as textMixin;
@use "src/css/mixins/customTextMixin" as customTextMixin;

@use 'quasar/src/css/variables.sass' as qVariables;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

@media (max-width: baseSize.$layout-breakpoint-md) {
  .map-src-price-val {
    @include customTextMixin.custom-text-script-mixin('b11', true);
  }

  .map-src-price-desc {
    @include customTextMixin.custom-text-script-mixin('b9');
  }
}

@media (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .map-src-price-val {
    @include textMixin.text-mixin(qVariables.$body2);
  }

  .map-src-price-desc {
    @include customTextMixin.custom-text-script-mixin('b11');
  }
}

