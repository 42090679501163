@use "sass:map";
@use "sass:string";
@use "src/css/size.variables.scss" as sizeVar;
@use "src/css/mixins/paddingMixin" as paddingMixin;
@use "src/css/mixins/marginMixin" as marginMixin;

$padding-margin-list: (
  "left",
  "right",
  "top",
  "bottom",
  null
);

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

@each $name in $padding-margin-list {
  $position: "a";
  @if $name != null {
    $position: string.slice($name, 1, 1);
  }

  @each $sizeName, $size in sizeVar.$padding-size-map {
    .p#{$position}-#{$sizeName} {
      @include paddingMixin.padding-mixin($name, $sizeName);
    }

    .m#{$position}-#{$sizeName} {
      @include marginMixin.margin-mixin($name, $sizeName);
    }
  }
}