@media (min-width: 541px) {
  .uploader-cls {
    flex: 0 0 auto;
  }
  .row > .uploader-cls, .flex > .uploader-cls {
    min-width: 0;
    max-width: 100%;
    height: auto;
    width: 33.3333% !important;
  }
  .uploader-cls:nth-child(n+4) {
    padding-top: 16px;
  }
  .uploader-cls:nth-child(3n+1) {
    padding-right: 10px;
  }
  .uploader-cls:nth-child(3n+2) {
    padding-left: 4px;
    padding-right: 4px;
  }
  .uploader-cls:nth-child(3n+2) {
    padding-x: 4px;
  }
  .uploader-cls:nth-child(3n+3) {
    padding-left: 10px;
  }
}
@media (max-width: 540px) and (min-width: 361px) {
  .uploader-cls {
    flex: 0 0 auto;
  }
  .row > .uploader-cls, .flex > .uploader-cls {
    min-width: 0;
    max-width: 100%;
    height: auto;
    width: 50% !important;
  }
  .uploader-cls:nth-child(n+3) {
    padding-top: 16px;
  }
  .uploader-cls:nth-child(odd) {
    padding-right: 8px;
  }
  .uploader-cls:nth-child(even) {
    padding-left: 8px;
  }
}
@media (max-width: 360px) {
  .uploader-cls {
    flex: 0 0 auto;
  }
  .row > .uploader-cls, .flex > .uploader-cls {
    min-width: 0;
    max-width: 100%;
    height: auto;
    width: 100% !important;
  }
  .uploader-cls:not(:first-child) {
    padding-top: 16px;
  }
}