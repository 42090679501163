
@use 'src/css/components/map.scss';

@use "src/css/size.variables.scss" as baseSize;

@use 'quasar/src/css/variables' as qVariables;
@use 'src/css/quasar.variables.scss' as customQVariables;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';


.map-floating-action-btn {
  top: 0;
  right: 0;
}

@mixin tag-class($color, $font-color) {
  background-color: $color;
  border-radius: 8px;
  color: $font-color;
  font-size: 14px;
  padding: 5px 10px;
  border: 1px solid qVariables.$grey-5;
  border-color: qVariables.$grey-5;
  position: relative;
  font-family: 'Quicksand';

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    rotate: 45deg;

    width: 10px;
    height: 10px;

    border-right: 1px solid qVariables.$grey-5;
    border-bottom: 1px solid qVariables.$grey-5;
    background-color: $color;
  }
}

/* HTML marker styles */
.price-tag {
  @include tag-class(white, qVariables.$grey-8);

  &--selected {
    @include tag-class(customQVariables.$primary, white);
  }
}
