.slide-up-enter-active, .slide-up-leave-active {
  transition: all .1s ease;
}

.slide-up-enter, .slide-up-leave-to {
  margin-bottom: -10px;
}

.slide-up-enter-to, .slide-up-leave {
  margin-bottom: 0;
}
