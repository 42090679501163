
@use "src/css/mixins/sideMixin" as sideMixin;
@use 'src/css/quasar.variables' as customQVariables;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.v-dotted {
  border-left: 2px dotted;
  height: 20px;
}

.vl {
  border-left: 2px solid;
}

.border-accent, .border-positive {
  border: 3px solid;
}

.border-accent {
  border-color: customQVariables.$accent
}

.border-positive {
  border-color: customQVariables.$positive;
}

.non-first-margin:not(:first-child) {
  margin-top: 8px;
}

@media (min-width: (400px + 1px)) {
  .side-gt-400 {
    @include sideMixin.side-mixin;
  }

  .justify-gt-400 {
    justify-content: center;
  }
}

@media (min-width: (380px + 1px)) {
  .availability-side {
    width: 120px;
  }
}

@media (max-width: 380px) {
  .availability-side {
    padding-right: none;
    width: 100%;
  }
}
