
@use "src/css/size.variables.scss" as baseSize;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

@media (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .map-src-img {
    width: 169px;
    height: 100%;
  }

  .desc-width {
    width: calc(100% - 169px);
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) {
  .map-src-img {
    width: 128px;
    height: 100%;
  }

  .desc-width {
    width: calc(100% - 128px);
  }
}
