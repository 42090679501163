
@use "sass:map";

@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/colMixin" as colMixin;

@use 'src/css/quasar.variables.scss' as customQVariables;
@use 'src/css/modules/colors.scss' as customColors;
@use 'quasar/src/css/variables.sass' as qVariables;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.avail-sec-ht {
  height: 376px;
}

.date-select-wh {
  width: 326px;
}

.body--light {
  .date-selector-cls {
    .q-date__header {
      background-color: white !important;
      color:  qVariables.$grey-8 !important;

      border-bottom: 1px solid qVariables.$grey-4 !important;
    }
  }
}

.body--dark {
  .date-selector-cls {
    .q-date__header {
      background: map.get(
        customColors.$custom-color-map,
        "dark-variant"
      );
      color: white !important;

      border-bottom: 1px solid white !important;
    }
  }
}


@media (min-width: (baseSize.$layout-breakpoint-xl + 1px)) {
  .avail-col {
    width: calc(100% - 326px);
  }

  .date-str-display {
    display: none;
  }
}

@media (max-width: baseSize.$layout-breakpoint-xl) and (min-width: (baseSize.$layout-breakpoint-lg + 1px)){
  .avail-col {
    width: 100%;
  }

  .avail-date-display {
    display: none;
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) and (min-width: 761px){
  .avail-col {
    width: calc(100% - 326px);
  }

  .date-str-display {
    display: none;
  }
}


@media (max-width: 761px){
  .avail-col {
    width: 100%;
  }

  .avail-date-display {
    display: none;
  }
}
