@use "src/css/mixins/borderMixin.scss" as borderMixin;
@use 'quasar/src/css/variables' as qVariables;
@use 'src/css/quasar.variables' as customQVariables;

$round-map: (
  "": 80px,
  "ed": 5px,
  "ed__8": 8px,
  "ed__10": 10px,
  "ed__12": 12px,
  "ed__15": 15px,
  "ed__25": 25px
);

@each $key, $value in $round-map {
  .round#{$key} {
    border-radius: $value !important;
  }
}

.dot {
  border-radius: 50%;
  display: inline-block;
}

.bordered {
  border: 1px solid qVariables.$grey-4;

  &-input {
    border: 1px solid qVariables.$grey-5;
  }

  &-dark {
    border: 1px solid qVariables.$grey-8;
  }

  &-right {
    border-right: 2px solid qVariables.$grey-4
  }
}

@include borderMixin.border-mixin('border-thick', 'border');
@include borderMixin.border-mixin('border-grey', 'border-grey');

.calendar-border {
  border: 1px solid black;
}

.sku-border {
  border: 1px solid qVariables.$grey-8;
}




