
.day-wh {
  width: 52px;

  &-with-moq {
    width: 115px;
  }
}

.non-first-timing-pd:not(:first-child) {
  padding-top: 8px !important;
}

.ellipsis-transition {
  transition: max-height .3s;
}

.slide-down-prev {
  max-height: 200px;
  overflow: hidden;
}

.slide-down-full {
  max-height: 1000px;
}
