@use "sass:map";

@use "src/css/mixins/overlayMixin.scss" as overlayMixin;
@use "src/css/mixins/borderMixin.scss" as borderMixin;

@use "src/css/mixins/standardScrollbarMixin.scss" as standardScrollbarMixin;

@use 'quasar/src/css/variables.sass' as qVariables;
@use 'src/css/quasar.variables.scss' as customQVariables;
@use 'src/css/modules/colors.scss' as customColors;

.body--dark {
  @include borderMixin.border-mixin("border-thin", "border-responsive");
  
  @include standardScrollbarMixin.dark-mixin();

  .sticky-wrap,
  .dialog-wrap,
  .q-table__middle,
  .scrollable,
  .custom-scroll-area,
  .custom-scroll-area-vertical {
    @include standardScrollbarMixin.dark-mixin();
  }

  .q-panel scroll {
    @include standardScrollbarMixin.dark-mixin(); 
  }

  .custom-scroll-area::-webkit-scrollbar-thumb {
    border: 4px solid map.get(
      customColors.$custom-color-map,
      "dark-variant"
    );
  }


  .hide-on-dark {
    display: none;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;
    @include overlayMixin.base-overlay;
  }

  .separator::before,
  .separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #fff;
  }

  .black-white-text-color,
  .grey9-white-text-color,
  .grey8-white-text-color,
  .grey6-white-text-color,
  .grey10-white-text-color,
  .info-white-text-color,
  .primary-white-text-color {
    color: white;
  }

  .app-header-bottom-ld-responsive {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }

  .grey8-grey1-text-color,
  .grey10-grey1-text-color,
  .black-grey1-text-color {
    color: qVariables.$grey-1 !important;
  }

  .black-grey2-text-color,
  .grey8-grey2-text-color,
  .grey9-grey2-text-color {
    color: qVariables.$grey-2;
  }

  .black-grey4-text-color,
  .grey8-grey4-text-color {
    color: qVariables.$grey-4;
  }

  .grey8-grey5-text-color,
  .black-grey5-text-color {
    color: qVariables.$grey-5;
  }

  .grey4-grey8-text-color {
    color: qVariables.$grey-8;
  }

  .grey1-grey9-text-color {
    color: qVariables.$grey-9;
  }

  .white-dark-variant-text-color {
    color: map.get(
      customColors.$custom-color-map,
      "dark-variant"
    );
  }

  .primary-primary-variant-text-color,
  .info-primary-variant-text-color {
    color: map.get(
      customColors.$custom-color-map,
      "dark-variant"
    );
  }

  .primary-teal8-text-color {
    color: qVariables.$teal-8;
  }

  .white-dark-background,
  .grey4-dark-background {
    background-color: customQVariables.$dark;
  }

  .transparent-dark-variant-background,
  .white-dark-variant-background,
  .grey1-dark-variant-background,
  .grey2-dark-variant-background,
  .grey3-dark-variant-background,
  .grey4-dark-variant-background,
  .grey5-dark-variant-background,
  .grey7-dark-variant-background,
  .grey8-dark-variant-background {
    background-color: map.get(
      customColors.$custom-color-map,
      "dark-variant"
    );
  }

  .grey4-grey8-background {
    background-color: qVariables.$grey-8;
  }

  .grey1-grey9-background,
  .grey7-grey9-background {
    background-color: qVariables.$grey-9;
  }

  .grey2-grey10-background,
  .grey3-grey10-background {
    background-color: qVariables.$grey-10;
  }

  .primary-primary-variant-background,
  .info-primary-variant-background {
    background-color: map.get(
      customColors.$custom-color-map,
      "primary-variant"
    );
  }

  .grey3-primary-background {
    background-color: customQVariables.$primary;
  }

  .primary-teal8-background {
    background-color: qVariables.$teal-8;
  }

  .app-footer {
    background-color: customQVariables.$dark;
  }

  .show-more-gradient {
    background-image: linear-gradient(0deg, rgba(18, 18, 18, 0.8) 0%, rgba(18, 18, 18, 0.9) 75%, rgb(18, 18, 18) 100%);
  }

  .ellipsis-block-gradient {
    background-image: linear-gradient(180deg, rgba(18, 18, 18, 0.1) 0%, rgba(18, 18, 18, 0.2) 15%, rgba(18, 18, 18, 0.35) 30%, rgba(18, 18, 18, 0.6) 50%, rgba(18, 18, 18, 0.8) 75%, rgb(18, 18, 18) 100%);
  }

  .standard-card-section:hover {
    background-color: qVariables.$grey-9;
  }
}
