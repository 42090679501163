.ellipsis {
  &-show-less {
    height: 120px;

    &__review {
      max-height: 80px;
    }
  }

  &-2-show-less {
    height: 80px;
  }

  &-1-show-less {
    height: 40px;
  }

  &-1-line {
    -webkit-line-clamp: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  &-block-size {
    padding-top: 6px;
    max-width: 80vw;
  } 
}

.text-ellipsis {
  text-overflow: ellipsis
}