@use "src/css/mixins/customTextMixin" as ct;
@use "src/css/text.variables" as ctvar;

h1 {
  font-size: 2.5rem;
  line-height: 2.5rem;
  letter-spacing: 0.0125rem;
}

h2 {
  font-size: 2.125rem;
  line-height: 2.125rem;
  letter-spacing: 0.0125rem;
  margin: 0;
}

h3 {
  font-size: 1.75rem;
}

@each $key, $value in ctvar.$custom-text-font-size {
  .custom-text-#{$key} {
    @include ct.custom-text-script-mixin($key, true);
  }
}

@each $value in ctvar.$line-height-list {
  .custom-text-line-height-#{$value} {
    line-height: #{$value}px;
  }
}

.min-line-height {
  line-height: 1.2rem;

  &_sm {
    line-height: 1rem;
  }
}

.font-weight {
  &-none {
    font-weight: 100;
  }
  &-light {
    font-weight: 300;
  }
  &-normal {
    font-weight: 400 !important;
  }
  &-700 {
    font-weight: 700 !important;
  }
  &-bold-important {
    font-weight: bold !important;
  }
  &-bolder-important {
    font-weight: 900 !important;
  }
}

.strikethrough {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.wrap-break {
  word-wrap: break-word;
}

.text-wrap {
  @extend .wrap-break;
  word-break: break-word;
  overflow-wrap: break-word;
}

.break-all {
  @extend .text-wrap;
  -ms-word-break: break-all;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}