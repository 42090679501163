
@use 'quasar/src/css/variables' as qVariables;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.coworking-logo {
  width: 14px;
  height: 20px;
}

@media (min-width: (450px + 1px)) {
  .filter-btn-wh {
    width: 85px;
  }
}

@media (max-width: 450px) {
  .filter-btn-wh {
    width: 75px;
  }
}

.filter-btn-wh-border {
  border: 1px solid;
  border-color: qVariables.$grey-5;
}

.filter-btn-wh i {
  font-size: 1.25rem !important;
}

@media (min-width: (400px + 1px)) {
  .clearable-sm-hide {
    display: none !important;
  }
}

.filter-section-min-wh {
  min-width: 335px;
}

.clearable-sm {
  width: 27px;
  height: 27px;
}
