
@use "src/css/size.variables.scss" as baseSize;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

@media (min-width: (baseSize.$layout-breakpoint-sm + 1px)) {
  .host-img-size {
    width: 5rem;
    height: 5rem;
  }

  .host-section-desc {
  width: calc(100% - 5rem);
}
}

@media (max-width: baseSize.$layout-breakpoint-sm) {
  .full-ht-lt-sm {
    height: 100%;
  }

  .host-img-size {
    width: 3.5rem;
    height: 3.5rem;
  }

  .host-section-desc {
    width: calc(100% - 3.5rem);
  }
}
