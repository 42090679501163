@use "sass:math";
@use "src/css/helpers/math" as *;
@use "quasar/src/css/variables.sass" as qVariables;

@mixin col-script-mixin($size) {
  @if $size < 0 {
    @error "col cannot be negative";
  }

  & {
    flex: 0 0 auto;
  }
  
  .row > &, .flex > & {
    min-width: 0;
    max-width: 100%;
    height: auto;
    width: toFixed(percentage(math.div($size, qVariables.$flex-cols)), 10000) !important;
  }
}
