
.col-12-impt {
  min-width: 0 !important;
  max-width: 100% !important;
  height: auto !important;
  width: 100% !important;
}

.fixed-info-card-img-ht {
  height: 160px !important;
}
