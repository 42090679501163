@use 'quasar/src/css/variables' as qVariables;

$border-set-map: (
  'border-thick': 2px solid qVariables.$grey-5,
  'border-grey': 1px solid qVariables.$grey-5,
  'border-mid': 1px solid black,
  'border-thin': 0.5px solid black
);
$border-type-arr: (
  'all', 'right', 'left', 'bottom', 'top'
);

@mixin border-mixin($type, $class-name) {
  @each $value in $border-type-arr {
    @if $value == 'all' {
      .#{$class-name} {
        border: map-get($border-set-map, $type);
      }
    } @else {
      .#{$class-name}-#{$value} {
        border-#{$value}: map-get($border-set-map, $type);
      }
    }
  }
}
