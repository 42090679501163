
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/colMixin" as colMixin;
@use "src/css/mixins/paddingMixin" as paddingMixin;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.addon-item:not(:first-child) {
  @include paddingMixin.padding-mixin('top', 'md');
}

@media (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .uploader-size {
    width: 180px;
    height: 120px;
  }

  .input-section-wh {
    max-width: calc(100% - 196px)
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) and (min-width: (baseSize.$layout-breakpoint-sm + 1px)) {
  .uploader-size {
    width: 150px;
    height: 90px;
  }

  .input-section-wh {
    max-width: calc(100% - 166px)
  }
}

@media (min-width: (baseSize.$layout-breakpoint-sm + 1px)) {
  .upload-section {
    @include paddingMixin.padding-mixin('right', 'md');
  }
}

@media (max-width: baseSize.$layout-breakpoint-sm) {
  .uploader-size {
    width: 180px;
    height: 120px;
  }

  .upload-section {
    @include paddingMixin.padding-mixin('bottom', 'md');
  }

  .input-section-wh {
    max-width: 100%
  }
}
