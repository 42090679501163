
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/customTextMixin" as customTextMixin;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.instant-text {
  background: rgba(37, 37, 37, 0.8);
  color: white;
  font-weight: bold;
  margin-top: 15px;
  padding: 3px 4px 3px 3px;
  line-height: 15px;
  border-radius: 0 8px 8px 0;
}

.search-card-like-share-size {
  font-size: 0.8rem;
}

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .from-text {
    @include customTextMixin.custom-text-script-mixin('b11', true);
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) {
  .from-text {
    @include customTextMixin.custom-text-script-mixin('b10', true);
  }
}
