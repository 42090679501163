
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/colMixin" as colMixin;

@media (min-width: (350px + 1px)) {
  .pax-popup-width {
    width: 350px;
  }

  .pax-options-col {
    @include colMixin.col-script-mixin(4);
  }
}

@media (max-width: 350px) {
  .pax-popup-width {
    width: calc(100vw - 16px);
  }
}

@media (max-width: 350px) and (min-width: (300px + 1px))  {
  .pax-options-col {
    @include colMixin.col-script-mixin(6);
  }
}

@media (max-width: 300px) {
  .pax-options-col {
    @include colMixin.col-script-mixin(12);
  }
}
