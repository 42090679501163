@-webkit-keyframes bounce { 
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);} 
  40% {-webkit-transform: translateY(-30px);} 
  60% {-webkit-transform: translateY(-15px);} 
} 

@keyframes bounce { 
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-30px);} 
  60% {transform: translateY(-15px);} 
}

.animated { 
  -webkit-animation-duration: 1s;
  animation-duration: 1s; 
  -webkit-animation-fill-mode: both; 
  animation-fill-mode: both; 
}

.bounce { 
  -webkit-animation-name: bounce; 
  animation-name: bounce; 
}