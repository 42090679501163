.slide-fade-enter-active {
  transition: all .5s ease;
  transition-delay: .1s;
}

.slide-fade-leave-active {
  transition: all .1s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(0px);
  opacity: 0;
}
