
.whatsapp-pd {
  padding-right: 4px;
}

@media (max-width: 525px) {
  .whatsapp-pd {
    padding-bottom: 60px;
  }
}

@media (min-width: 526px) {
  .whatsapp-pd {
    padding-bottom: 16px;
  }
}
