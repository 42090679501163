.page-slide-fade-enter-active {
  transition: all .2s ease;
  transition-delay: .2s;
}

.page-slide-fade-leave-active {
  transition: all .2s ease;
}

.page-slide-fade-enter, .page-slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}