
@use "src/css/size.variables.scss" as baseSize;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.respond-wh {
  max-width: calc(100% - 18px)
}

@media (max-width: baseSize.$layout-breakpoint-md) {
  .map-src-review-icon {
    font-size: 14px;
  }
}

@media (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .map-src-review-icon {
    font-size: 16px;
  }
}

@media (min-width: (380px + 1px)) {
  .pt-sm-gt-380 {
    padding-top: 8px;
  }
}
