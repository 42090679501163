@use "sass:math" as sassMath;

@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/paddingMixin" as paddingMixin;
@use "src/css/mixins/colMixin" as colMixin;
@use "src/css/mixins/textMixin" as textMixin;
@use "src/css/mixins/customTextMixin" as customTextMixin;
@use "src/css/mixins/imageMixin" as imageMixin;
@use "src/css/mixins/pagePaddingMixin" as pagePaddingMixin;
@use "src/css/mixins/landingMixin" as landingMixin;
@use "src/css/mixins/marginMixin" as marginMixin;
@use "src/css/mixins/sideMixin" as sideMixin;

@use 'src/css/quasar.variables.scss' as customQVariables;
@use 'quasar/src/css/variables.sass' as qVariables;

@media (min-width: (1500px + 1px)) {
  .host-search-card-col {
    @include colMixin.col-script-mixin(3)
  }


  .host-search-card-col:nth-child(4n + 1) {
    padding-right: 5px
  }

  .host-search-card-col:nth-child(4n + 2),
  .host-search-card-col:nth-child(4n + 3) {
    @include paddingMixin.padding-mixin('x', 'xs');
  }

  .host-search-card-col:nth-child(4n + 4) {
    padding-left: 5px
  }
}

@media (min-width: (baseSize.$layout-breakpoint-xl + 1px)) {
  .standard-page-col,
  .full-mobile-width-page-col,
  .general-home-tbl-col {
    @include colMixin.col-script-mixin(9);
  }

  .host-reg-col {
    @include colMixin.col-script-mixin(6);
  }

  .listing-info-col {
    @include colMixin.col-script-mixin(4);
  }

  .listing-info-col:nth-child(3n + 1) {
    @include paddingMixin.padding-mixin('right', 'xs');
  }

  .listing-info-col:nth-child(3n + 2) {
    @include paddingMixin.padding-mixin('x', 'xxs');
  }

  .listing-info-col:nth-child(3n + 3) {
    @include paddingMixin.padding-mixin('left', 'xs');
  }

  .listing-info-col:nth-child(n + 4) {
    @include paddingMixin.padding-mixin('top', 'sm');
  }

  .listing-tag-col,
  .general-home-img-col {
    @include colMixin.col-script-mixin(3);
  }

  .listing-tag-col:nth-child(n + 5) {
    @include paddingMixin.padding-mixin('top', 'md');
  }

  .listing-tag-col:nth-child(4n + 1) {
    padding-right: 10px
  }

  .listing-tag-col:nth-child(4n + 2),
  .listing-tag-col:nth-child(4n + 3) {
    padding-right: 5px;
    padding-left: 5px;
  }

  .listing-tag-col:nth-child(4n + 4) {
    padding-left: 10px
  }

  @each $padding-side in baseSize.$padding-side-list {
    @each $padding-size in baseSize.$break-point-padding-size-list {
      @if $padding-side == 'a' {
        .p#{str-slice($padding-side, 1, 1)}-#{$padding-size}-gt-xl {
          @include paddingMixin.padding-mixin(null, $padding-size);
        }
      } @else {
        .p#{str-slice($padding-side, 1, 1)}-#{$padding-size}-gt-xl {
          @include paddingMixin.padding-mixin($padding-side, $padding-size);
        }
      }
    }
  }

  .success-title {
    @include textMixin.text-mixin(qVariables.$h6);
  }
  
  .listing-page-padding,
  .listing-banner-page-padding {
    padding-left: 32px;
    padding-right: 32px;
    max-width: 1280px !important;
  }

  .listing-tag-img-ht {
    height: calc((((48vw - 16px) / 3) - 16px) / 16 * 9);
    max-height: 100px;
  }

  .landing-title {
    font-size: 2rem;
    line-height: 2.2rem;

    &-getspaces {
      font-size: 3.125rem;
      line-height: 3.5rem;
    }
  }

  .landing-desc {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }

  .landing-credit-desc {
    font-size: 1.125rem;
    line-height: 1.6rem;
  }
}

@media (max-width: baseSize.$layout-breakpoint-xl) and (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .standard-page-col,
  .full-mobile-width-page-col {
    @include colMixin.col-script-mixin(10);
  }

  .host-reg-col,
  .general-home-tbl-col {
    @include colMixin.col-script-mixin(8);
  }

  .general-home-img-col {
    @include colMixin.col-script-mixin(4);
  }

  .listing-banner-page-padding {
    @include pagePaddingMixin.page-padding-mixin("lg");
  }

  .landing-title {
    font-size: 2rem;
    line-height: 2.2rem;

    &-getspaces {
      font-size: 3.125rem;
      line-height: 3.25rem;
    }
  }

  .landing-desc {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }

  .landing-credit-desc {
    font-size: 1.125rem;
    line-height: 1.6rem;
  }

  .listing-tag-img-ht {
    height: calc((((54vw - 16px) / 3) - 16px) / 16 * 9);
  }
}

@media (min-width: (baseSize.$layout-breakpoint-mlg + 1px)) {
  .head-block,
  .head-block-new {
    align-self: center;
    position: absolute;
    top: calc(50% - 150px);
  }

  .details-dialog-content-col {
    @include colMixin.col-script-mixin(4);
  }
}

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) and (max-width: baseSize.$layout-breakpoint-mlg) {
  .details-dialog-content-col {
    @include colMixin.col-script-mixin(6);
  }
}

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .col-break-lg-3-6,
  .line-col {
    @include colMixin.col-script-mixin(3);
  }
  
  .reason-col,
  .info-col,
  .col-break-lg-4-12,
  .main-table-col {
    @include colMixin.col-script-mixin(4);
  }

  .host-comment-reason-col,
  .col-break-lg-5-12 {
    @include colMixin.col-script-mixin(5);
  }

  .standard-landing-col,
  .col-break-lg-6-12 {
    @include colMixin.col-script-mixin(6);
  }

  .convince-col,
  .col-break-lg-7-12 {
    @include colMixin.col-script-mixin(7);
  }

  .col-break-lg-8-12 {
    @include colMixin.col-script-mixin(8);
  }

  .create-listing-col {
    @include colMixin.col-script-mixin(9);
  }

  .viewing-title {
    @include textMixin.text-mixin(qVariables.$h4);
  }

  .src-desc-txt {
    @include textMixin.text-mixin(qVariables.$body2);
  }

  .viewing-filter {
    @include textMixin.text-mixin(qVariables.$body1);
  }
  
  .order-first-gt-lg {
    order: -10000;
  }

  .app-banner-background {
    min-height: 250px !important;
    @include landingMixin.banner-background-mixin;
  }

  .landing-custom-title {
    font-size: 3.125rem;
    line-height: 3.125rem;
  }

  .landing-custom-desc {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  .search-price-text {
    font-weight: bold !important;
    @include textMixin.text-mixin(qVariables.$body1); 
  }

  .banner-btn-font {
    font-size: 14px;
  }

  .search-desc-text {
    @include textMixin.text-mixin(qVariables.$caption);
  }

  .search-standard-text {
    @include customTextMixin.custom-text-script-mixin('b11', true);
  }

  .list-content {
    width: 60%;
    padding-right: 8px;
    border-right: 1px solid #c5c5c5;
  }

  .home-cat-card-min-ht {
    min-height: 48px;
  }

  .store-icon {
    width: 105px;
    height: 33px;
  }

  .editor-pic {
    width: 240px;
    height: 160px;
    max-width: 300px;
    max-height: 200px;
  }

  .image-section-width {
    width: 250px;
  }

  .edit-listing-desc-width {
    max-width: calc(100% - 250px - 32px);
  }

  .viewing-width {
    width: calc(100% - 6px);
  }

  .layout-padding {
    &-desktop {
      padding-top: baseSize.$header-ht-desktop-login !important;
    }

    &-mobile {
      padding-top: baseSize.$header-ht !important;
    }

    &-app {
      padding-top: calc(baseSize.$header-ht + env(safe-area-inset-top)) !important;
    }
  }

  .arrow-size {
    font-size: 3rem;
  }
  
  .row-gt-lg {
    display: flex;
    flex-wrap: wrap;
  }

  @each $padding-side in baseSize.$padding-side-list {
    @each $padding-size in baseSize.$break-point-padding-size-list {
      @if $padding-side == 'a' {
        .p#{str-slice($padding-side, 1, 1)}-#{$padding-size}-gt-lg {
          @include paddingMixin.padding-mixin(null, $padding-size);
        }
      } @else {
        .p#{str-slice($padding-side, 1, 1)}-#{$padding-size}-gt-lg {
          @include paddingMixin.padding-mixin($padding-side, $padding-size);
        }
      }
    }
  }

  .credit-card-padding {
    @include paddingMixin.padding-mixin('x', 'sm');
  }

  .info-main-padding {
    @include paddingMixin.padding-mixin("bottom", "sm");
  }

  .confirmation-main-padding {
    @include paddingMixin.padding-mixin(null, "md");
  }

  .convince-col {
    @include paddingMixin.padding-mixin("x", "md");
  }

  .rewards-padding {
    @include paddingMixin.padding-mixin('y', 'lg');
  }

  .page-padding,
  .dashboard-page-padding {
    @include pagePaddingMixin.page-padding-mixin('sm');
  }

  .positive-gt-lg {
    color: customQVariables.$positive;
  }

  .text-upper-gt-lg {
    text-transform: uppercase;
  }
}