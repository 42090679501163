
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/marginMixin" as marginMixin;
@use "src/css/mixins/paddingMixin" as paddingMixin;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.recurring-set-wh {
  @include marginMixin.margin-mixin('top', 'md');
}

@media (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .recurring-set-wh {
    width: 224px;
    @include marginMixin.margin-mixin('right', 'md');
  }

  .card-details-section-pd {
    padding-top: 0 !important;
    @include paddingMixin.padding-mixin('x', 'md');
    @include paddingMixin.padding-mixin('bottom', 'md');
  }

  .card-btn-section-pd {
    padding-top: 0 !important;
    @include paddingMixin.padding-mixin('bottom', 'md');
    @include paddingMixin.padding-mixin('x', 'md');
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) {
  .recurring-set-wh {
    width: 100%
  }

  .card-details-section-pd {
    @include paddingMixin.padding-mixin('x', 'sm');
    @include paddingMixin.padding-mixin('y', 'sm');
  }

  .card-btn-section-pd {
    padding-top: 0 !important;
    @include paddingMixin.padding-mixin('bottom', 'sm');
    @include paddingMixin.padding-mixin('x', 'sm');
  }
}
