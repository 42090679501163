@use "sass:map";

$page-padding-map: (
  "xxl": 1vw, "xl": 2vw, "lg": 5vw,
  "md": 10vw, "sm": 15vw, "xs": 20vw
);

@mixin page-padding-mixin($size) {
  padding-left: map.get($page-padding-map, $size);
  padding-right: map.get($page-padding-map, $size);
}
