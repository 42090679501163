
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/textMixin" as textMixin;

@use 'quasar/src/css/variables.sass' as qVariables;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

@media (min-width: (baseSize.$layout-breakpoint-sm + 1px)) {
  .credit-banner-font-size {
    @include textMixin.text-mixin(qVariables.$body2);
  }
}

@media (max-width: baseSize.$layout-breakpoint-sm) {
  .credit-banner-font-size {
    @include textMixin.text-mixin(qVariables.$caption);
  }
}
