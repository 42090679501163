
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/colMixin" as colMixin;
@use "src/css/mixins/paddingMixin" as paddingMixin;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.q-field__before {
  align-self: center;
}

.hide-last-separator:last-child {
  display: none;
}

@media (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .file-col {
    @include colMixin.col-script-mixin(4);
  }

  .file-edit-padding:nth-child(2n + 1) {
    @include paddingMixin.padding-mixin('right', 'sm')
  }

  .file-edit-padding:nth-child(2n + 2) {
    @include paddingMixin.padding-mixin('left', 'sm')
  }

  .file-edit-padding:nth-child(n + 3) {
    @include paddingMixin.padding-mixin('top', 'md')
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) {
  .file-edit-padding:nth-child(n + 2) {
    @include paddingMixin.padding-mixin('top', 'md')
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) and (min-width: (baseSize.$layout-breakpoint-sm + 1px)) {
  .file-col {
    @include colMixin.col-script-mixin(6);
  }
}

@media (max-width: baseSize.$layout-breakpoint-sm) {
  .file-col {
    @include colMixin.col-script-mixin(12);
  }
}
