
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/colMixin" as colMixin;
@use "src/css/mixins/paddingMixin" as paddingMixin;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .set-wh {
    width: calc(66.667% - 1px)
  }

  .price-wh {
    width: 33.33%
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) {
  .set-wh,
  .price-wh {
    width: 100%
  }
}

.no-padding-top {
  padding-top: 0 !important;
}
