@use 'quasar/src/css/variables.sass' as qVariables;

@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/pagePaddingMixin" as pagePaddingMixin;

.create-listing-border-right {
  border-right: 1px solid qVariables.$grey-4;
}

.full-page {
  &-desktop {
    height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login);
  }

  &-mobile {
    height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht);
  }

  &-app {
    height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - baseSize.$app-footer-ht);
    height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - env(safe-area-inset-bottom) - env(safe-area-inset-top) - baseSize.$app-footer-ht);
  }

  &-app-keyboard {
    height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht);
    height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - env(safe-area-inset-bottom) - env(safe-area-inset-top));
  }
}

@each $sizeName, $size in pagePaddingMixin.$page-padding-map {
  .page-paddings__#{$sizeName} {
    @include pagePaddingMixin.page-padding-mixin($sizeName);
  }
}
