
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/colMixin" as colMixin;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.resource-card-single {
  width: 300px !important;
  margin-right: 16px !important;
}

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .resource-card-area-ht {
    height: 100%;
  }

  .resource-card-area-single-ht {
    height: 410px;
  }

  .resources-img-ht {
    padding-top: 66.6667%;
    height: 100%;
  }
}

@media (max-width: (baseSize.$layout-breakpoint-lg)) {
  .scroll-area-content {
    display: flex;
  }

  .resource-card-area-ht,
  .resource-card-area-single-ht {
    height: 368px;
  }

  .resource-card {
    width: 300px !important;
    margin-right: 16px !important;
  }

  .resources-img-ht {
    height: 160px;
  }
}
