
.scroll-btn-pos {
  position: absolute;
  width: 34px !important;
}

.left-btn-offset {
  left: 17px;
}

.right-btn-offset {
  right: 17px;
}
