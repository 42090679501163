
                @use "modules/quicksand";
                @use "size.variables.scss" as sizeVar;
              @charset "UTF-8";

// @use "modules/quicksand";
// @use "size.variables.scss" as sizeVar;
// @use 'src/css/quasar.variables.scss' as customQVariables;

@use "modules/colors";
@use "modules/icons";
@use "modules/page";
@use "modules/text";
@use "modules/paddings";
@use "modules/standardSizes";

@use "components/cart";
@use "components/dialog";
@use "components/ellipsis";
@use "components/genHome.scss";

@use "groups/border";
@use "groups/customQ";
@use "groups/dark";
@use "groups/font";
@use "groups/image";
@use "groups/light";
@use "groups/opacity";
@use "groups/padding";
@use "groups/separator";
@use "groups/size";
@use "groups/sizeHidden";
@use "groups/sticky";
@use "groups/z";

@use "pageWidthSpecific/desktop";
@use "pageWidthSpecific/mobile";
@use "pageWidthSpecific/neutral";

@use "animation/bounce.scss";

@use "transitions/fade";
@use "transitions/page-slide-fade";
@use "transitions/slide-down";
@use "transitions/slide-fade";
@use "transitions/slide-right";
@use "transitions/slide-up";

@use "modules/customFont.scss";
@use "modules/getFont.scss";

@use "src/features/search/css/search.scss";
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

div, span, p {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  -ms-word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: none;
  hyphens: auto;
}

.normal-break,
.normal-break * {
  word-wrap: normal !important;
  word-break: normal !important;
  overflow-wrap: normal !important;
  -ms-word-break: normal !important;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: none !important;
  -moz-hyphens: none !important;
  -webkit-hyphens: none !important;
  hyphens: none !important;
}

.gen-home-content,
.gen-home-title,
.gen-home-desc {
  @extend .normal-break;
}

/* Base */
a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  color: inherit;
}

p {
  margin: 0 0 0;
}

.policies {
  a {
    text-decoration: none;
    color: $primary;
  }

  a:visited {
    color: $primary;
  }

  p {
    padding-top: 8px;
  }
}

.smooth-scroll {
  scroll-behavior: smooth;
}

.immediate-scroll {
  scroll-behavior: auto;
}

.full-dialog-align {
  .q-dialog__inner {
    align-items: start !important;
  }
}

.q-btn {
  .q-btn__content {
    span::first-letter {
      text-transform: capitalize !important;
    }

    span {
      text-transform: lowercase !important;
    }
  }
}

.iosPaddingClass {
  padding-top: env(safe-area-inset-top);
}

.iosHeaderHeight {
  height: env(safe-area-inset-top);
}

.safeFooter {
  height: calc(50px + env(safe-area-inset-bottom));
}

.footerClass {
  margin-bottom: env(safe-area-inset-bottom);
}

.no-wrap {
  flex-wrap: nowrap;
}

.no-min-height {
  min-height: 0px !important;
}

.hide {
  display: none;
}

.show-line-break {
  white-space: pre-line;
}

.q-field {
  cursor: pointer;
}

.scrollable {
  overflow: overlay;
  padding: 0;
}

.custom-scroll-area-swipe-only,
.hide-overflow {
  overflow: hidden;
}

.custom-scroll-area,
.custom-scroll-area-hide-bar {
  overflow-x: scroll;
  overflow-y: hidden;
}

.custom-scroll-area-vertical,
.custom-scroll-area-vertical-hide-bar {
  overflow-x: hidden;
  overflow-y: scroll;
}

.custom-scroll-area-vertical-hide-bar,
.custom-scroll-area-swipe-only,
.custom-scroll-area-hide-bar {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
  }

  &::-webkit-scrollbar-track {
    border: none;
    width: 0;
  }

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-track-piece,
  &::-webkit-scrollbar-corner,
  &::-webkit-resizer {
    display: none;
  }
}

.max-vp-ht {
  height: calc((var(--vh, 1vh) * 100)) !important;
  height: calc((var(--vh, 1vh) * 100) - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
}

/* Misc */
.ul-inline {
  padding-inline-start: 16px !important;
}

hr.normal {
  border-top: 1px solid $grey-4;
}

.tab-panel-disable-scroll {
  .q-panel {
    overflow: hidden !important;
  }
}

.disable-route {
  pointer-events: none;
}

.cursor-move {
  cursor: url('https://dminqct125wnz.cloudfront.net/common/cursor/openhand.cur'), auto;
}

.cursor-move:active {
  cursor: grabbing;
}

.disable-pointer-evt {
  pointer-events: none;
}

.sharp-square {
  border-radius: 0;
}