
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/paddingMixin" as paddingMixin;

@use "src/css/components/grecaptcha.scss";
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.form-img-max-ht {
  height: 100%
}

.form-panel-ht {
  height: calc(100% - 45px - 88px)
}

.form-panel-max-wh {
  width: 100%;
  max-width: 540px;
}

@media (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .full-panel-height {
    &-desktop {
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login) !important;
    }

    &-mobile {
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht) !important;
    }

    &-app {
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - baseSize.$app-footer-ht) !important;
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - env(safe-area-inset-bottom) - env(safe-area-inset-top) - baseSize.$app-footer-ht) !important;
    }
  }

  .form-panel-ht-app {
    height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - baseSize.$app-footer-ht - 45px - 88px) !important;
    height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - env(safe-area-inset-bottom) - env(safe-area-inset-top) - baseSize.$app-footer-ht - 45px - 88px) !important;
  }

  .form-panel-ht-app-keyboard {
    height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 45px - 88px) !important;
    height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - env(safe-area-inset-bottom) - env(safe-area-inset-top) - 45px - 88px) !important;
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) {
  .form-img-max-ht {
    height: 220px !important;
  }

  .form-panel-max-wh {
    @include paddingMixin.padding-mixin('bottom', 'md');
  }
  
  .full-panel-height-app,
  .full-panel-height-desktop,
  .full-panel-height-mobile {
    height: calc(100% - 220px) !important;
  }

  .form-panel-ht-app {
    height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - baseSize.$app-footer-ht - 45px - 88px - 220px) !important;
    height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - env(safe-area-inset-bottom) - env(safe-area-inset-top) - baseSize.$app-footer-ht - 45px - 88px - 220px) !important;
  }

  .form-panel-ht-app-keyboard {
    height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 45px - 88px) !important;
    height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - env(safe-area-inset-bottom) - env(safe-area-inset-top) - 45px - 88px) !important;
  }
}
