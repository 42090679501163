@font-face {
  font-family: 'Quicksand';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('https://dminqct125wnz.cloudfront.net/common/font/Quicksand/Quicksand-Medium.eot');
  src: url('https://dminqct125wnz.cloudfront.net/common/font/Quicksand/Quicksand-Medium.woff2')
      format('woff2'),
    url('https://dminqct125wnz.cloudfront.net/common/font/Quicksand/Quicksand-Medium.woff')
      format('woff'),
    url('https://dminqct125wnz.cloudfront.net/common/font/Quicksand/Quicksand-Medium.ttf')
      format('truetype'),
    url('https://dminqct125wnz.cloudfront.net/common/font/Quicksand/Quicksand-Medium.eot')
      format('embedded-opentype');
}

@font-face {
  font-family: 'Quicksand';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('https://dminqct125wnz.cloudfront.net/common/font/Quicksand/Quicksand-700.eot');
  src: url('https://dminqct125wnz.cloudfront.net/common/font/Quicksand/Quicksand-700.woff2')
    format('woff2'),
  url('https://dminqct125wnz.cloudfront.net/common/font/Quicksand/Quicksand-700.woff')
    format('woff'),
  url('https://dminqct125wnz.cloudfront.net/common/font/Quicksand/Quicksand-700.ttf')
    format('truetype'),
  url('https://dminqct125wnz.cloudfront.net/common/font/Quicksand/Quicksand-700.eot')
    format('embedded-opentype');
}

.quicksand {
  font-weight: normal;
  font-family: 'Quicksand';
}

.quicksand-bold {
  font-weight: bold !important;
  font-family: 'Quicksand';
}