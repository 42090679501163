
@use "src/css/size.variables.scss" as baseSize;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .purchase-area-wh {
    width: calc(100% - 120px - 120px - 180px - 100px - 120px);
  }
}

@media (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .first-section-wh {
    width: 120px
  }

  .name-len {
    width: calc(100px - 16px);
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) {
  .first-section-wh {
    width: 100%
  }
}

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .ls-wh {
    width: 120px
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) {
  .ls-wh {
    width: 100%;
    text-align: right;
  }
}

@media (max-width: 800px) {
  .hide-lt-800 {
    display: none;
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) and (min-width: (700px + 1px)) {
  .purchase-area-wh {
    width: calc(100% - 120px - 120px - 180px - 120px);
  }
}

@media (max-width: 700px) {
  .hide-lt-700 {
    display: none;
  }
}

@media (max-width: 700px) {
  .purchase-area-wh {
    width: calc(100% - 120px - 180px - 120px);
  }
}
