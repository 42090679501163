
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/colMixin" as colMixin;
@use "src/css/mixins/marginMixin" as marginMixin;
@use "src/css/mixins/paddingMixin" as paddingMixin;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.landing-block-new {
  position: relative;
}

.concierge-min-ht {
  min-height: 360px;
}

.card-desc:nth-child(n + 2) {
  @include paddingMixin.padding-mixin("top", "xlg");
}

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .home-top-card-width {
    @include colMixin.col-script-mixin(4);
  }

  .home-top-card-width-two {
    @include colMixin.col-script-mixin(6);
  }

  .home-top-card-width-one {
    @include colMixin.col-script-mixin(12);
    @include paddingMixin.padding-mixin("right", "md");
  }

  .landing-block-new {
    height: 66.67vw;
    max-height: calc((var(--vh, 1vh) * 100) - 69px - 48px - 69px);
  }

  .compare {
    &-how-to-wh {
      width: calc(50% - 8px);
    }

    &-card-how-to-wh {
      width: 100%
    }


    &-how-to-wh,
    &-card-how-to-wh {
      @include paddingMixin.padding-mixin("top", "xs");
    }

    &-how-to-wh:nth-child(n + 1) {
      @include paddingMixin.padding-mixin("right", "sm");
      @include paddingMixin.padding-mixin("left", "xs");
    }

    &-how-to-wh:nth-child(n + 2) {
      @include paddingMixin.padding-mixin("right", "xs");
      @include paddingMixin.padding-mixin("left", "sm");
    }

    &-how-to-scroll-ht {
      height: 100%
    }

    &-scroll-wrap {
      flex-wrap: wrap;
    }

    &-card-size {
      @include colMixin.col-script-mixin(4);
    }
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) {
  .home-top-card-width-one {
    width: calc(100vw - 32px) !important;
    min-width: calc(100vw - 32px) !important;
  }

  .home-top-card-width:nth-child(n + 2),
  .home-top-card-width-two:nth-child(n + 2) {
    @include paddingMixin.padding-mixin("top", "lg");
  }

  .compare {
    &-scroll-wrap {
      flex-wrap: nowrap;
    }

    &-how-to-wh {
      @include marginMixin.margin-mixin("top", "xs");
    }

    &-how-to-wh:nth-child(n + 1) {
      @include marginMixin.margin-mixin("right", "sm");
      @include marginMixin.margin-mixin("left", "xs");
    }

    &-how-to-wh:nth-child(n + 2) {
      @include paddingMixin.padding-mixin("right", "xs");
      @include marginMixin.margin-mixin("right", "xs");
      @include marginMixin.margin-mixin("left", "sm");
    }

    &-card-size {
      width: 250px
    }
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) and (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .landing-block-new {
    height: 66.67vw;
    max-height: 339px;
  }

  .home-top-card-width,
  .home-top-card-width-two {
    width: 91.67vw !important;
    min-width: 91.67vw !important;
  }

  .compare {

    &-how-to-wh,
    &-card-how-to-wh {
      width: 400px
    }

    &-how-to-scroll-ht {
      height: 630px
    }
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) {
  .landing-block-new {
    height: 66.67vw;
    min-height: 250px;
  }

  .home-top-card-width,
  .home-top-card-width-two {
    width: 100%;
  }

  .compare {
    &-how-to-wh {
      width: 100%;
      margin-bottom: 8px;
      margin-right: 8px;
    }

    &-card-how-to-wh {
      width: calc(100vw - 32px - 18px);
    }

    &-how-to-scroll-ht {
      height: 100%;
    }
  }
}
