@use 'src/css/modules/colors.scss' as customColors;
@use 'quasar/src/css/variables.sass' as qVariables;

@use "sass:map";


@mixin standard {
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-track-piece,
  &::-webkit-scrollbar-corner,
  &::-webkit-resizer {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 40px;
  }
}

@mixin dark-mixin {
  @include standard();

  &::-webkit-scrollbar-track {
    border: none;
    width: 8px;
    background: map.get(
      customColors.$custom-color-map,
      "dark-variant"
    );
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid map.get(
      customColors.$custom-color-map,
      "dark-variant"
    );
    border-radius: 10px;
    background-color: qVariables.$grey-7;
  }
}


@mixin light-mixin {
  @include standard();
  
  &::-webkit-scrollbar-track {
    border: none;
    width: 8px;
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid white;
    border-radius: 10px;
    background-color: #a1a1a1;
  }
}