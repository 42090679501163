
@use "src/css/size.variables.scss" as baseSize;
@use 'quasar/src/css/variables.sass' as qVariables;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.host-reply-width {
  width: calc(100% - 28px)
}

.review-reply {
  border-left: 1px solid qVariables.$grey-5;
}


@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .review-desc-wh {
    width: calc(100% - 3.8rem - 16px)
  }

  .review-inner-desc-wh {
    width: 100%
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) {
  .review-desc-wh {
    width: 100%
  }

  .review-inner-desc-wh {
    width: calc(100% - 2.8rem - 16px)
  }
}
