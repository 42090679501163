
.img-overflow {
  overflow-x: scroll;
}

.fs-img-ht {
  height: auto;
  max-height: 100vh;
}

.fs-img-max-wh {
  max-width: calc(150vh - 96px);
}

.zoom-wh {
  &-0 {
    width: 100%
  }
}
