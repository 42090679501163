
@use "src/css/mixins/marginMixin" as marginMixin;
@use "src/css/mixins/paddingMixin" as paddingMixin;

.signup-flag-img {
  width: 120px;
  height: 80px;
}

.flag-size {
  width: 24px;
  height: 16px;
}

@media (min-width: 321px) {
  .gcal-btn-label:before {
    content: "Gcal Sync"
  }

  .create-listing-btn-label:before {
    content: "Create listing"
  }
}

@media (max-width: 320px) {
  .gcal-btn-label:before {
    content: "Sync"
  }

  .create-listing-btn-label:before {
    content: "Create"
  }
}
