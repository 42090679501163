
@use "sass:math" as sassMath;
@use "src/css/size.variables.scss" as baseSize;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

@media (min-width: 1477px) {
  .top-image-size {
    height: calc(sassMath.div(640px, 3) * 2)
  }
}


@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) and (max-width: 1476px) {
  .top-image-size {
    height: calc(sassMath.div(41.67vw, 3) * 2)
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) {
  .top-image-size {
    height: calc(sassMath.div(100vw, 16) * 9)
  }
}
