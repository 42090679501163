@use "src/css/size.variables.scss" as sizeVar;

.booking-card-top {
  &-desktop {
    top: sizeVar.$header-ht-desktop-login;
  }

  &-mobile {
    top: sizeVar.$header-ht;
  }
  
}