
.desc-card-wh {
  width: 166px;
}

.desc-card-ht  {
  min-height: calc(100% - 53px - 44px);
}

.desc-card-div {
  padding-top: 2px;
}

.desc-card-div:not(:last-child) {
  padding-right: 16px;
}

.desc-card-div:last-child {
  padding-right: 2px;
}

.desc-card-div:first-child {
  padding-left: 2px;
}
