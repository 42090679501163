
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/colMixin" as colMixin;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.custom-map {
  height: 210px;
  width: 100%;

  &__dense {
    height: 160px;
    width: 100%;
  }
}

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .map-col {
    @include colMixin.col-script-mixin(8);
  }

  .street-col {
    @include colMixin.col-script-mixin(9);
  }

  .block-col {
    @include colMixin.col-script-mixin(3);
  }

  .self-center-gt-lg {
    align-self: center;
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) and (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .street-col {
    @include colMixin.col-script-mixin(8);
  }

  .block-col {
    @include colMixin.col-script-mixin(4);
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) {
  .map-col {
    @include colMixin.col-script-mixin(12);
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) {
  .street-col {
    @include colMixin.col-script-mixin(12);
  }

  .block-col {
    @include colMixin.col-script-mixin(12);
  }
}
