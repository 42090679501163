@use "src/css/size.variables.scss" as baseSize;

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .create-listing-step-ht {

    &-desktop {
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login - 49px) !important;
    }

    &-mobile {
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 49px) !important;
    }

    &-app {
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - baseSize.$app-footer-ht) !important;
    }

    &-with-reset {
      &-desktop {
        min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login - 49px - 16px - 36px) !important;
      }

      &-mobile {
        min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 49px - 52px - 16px - 36px) !important;
      }

      &-app {
        min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 49px - 52px - 16px - 36px - baseSize.$app-footer-ht) !important;
      }
    }
  }
}


@media (max-width: baseSize.$layout-breakpoint-lg) {
  .create-listing-step-ht {
    &-desktop {
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login - 49px - 52px) !important;
    }

    &-mobile {
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 49px - 52px) !important;
    }

    &-app {
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 52px - baseSize.$app-footer-ht) !important;
    }

    &-with-reset {
      &-desktop {
        min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login - 49px - 16px - 36px - 52px) !important;
      }

      &-mobile {
        min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 49px - 52px - 16px - 36px - 52px) !important;
      }

      &-app {
        min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 49px - 52px - 16px - 36px - 52px - baseSize.$app-footer-ht) !important;
      }
    }
  }
}