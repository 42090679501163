@use 'quasar/src/css/variables' as qVariables;

.search_container__base {
  position: relative;
  background-color: white;

  &.with_value__active label {
    color: qVariables.$grey-7;
  }

  &.without_value__active label {
    transform: translate(5px, 2px) scale(0.7);
    padding-top: 2px;
    color: qVariables.$grey-7;
  }

  label {
    position: absolute;
    color: qVariables.$grey-7;
    font-weight: 200 !important;
    margin-left: 6px;
    transform-origin: top left;
    transition: all .1s ease-in-out;
    transform: translate(5px, 11px) scale(1);
  }

  .pac {
    &_input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 14px;
      text-overflow: ellipsis;
      width: 100%;
      font-weight: 100;
    }

    &_label {
      position: absolute;
      color: qVariables.$grey-7;
      margin-left: 6px;
      transform-origin: top left;
      transition: all .1s ease-in-out;
      transform: translate(5px, 13px) scale(1);
    
      &__focused {
        position: absolute;
        color: qVariables.$grey-7;
        font-family: 'Quicksand';
        transform: translate(5px, 2px) scale(1);

        &_sm {
          position: absolute;
          font-size: 10px !important;
          color: qVariables.$grey-7;
          font-family: 'Quicksand';
          transform: translate(5px, 2px) scale(1);
        }
      }
    }

    &_create {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 14px;
      text-overflow: ellipsis;
      color: qVariables.$grey-9;
      width: 100%;
      font-weight: 100;

      &:focus {
        border: 1px solid qVariables.$grey-3;
      }
    }
    
  } 
}

.search_container__filled {
  .pac_create {
    border: 1px solid qVariables.$grey-3;
    background-color: qVariables.$grey-3;

    &:focus {
      border: 1px solid qVariables.$grey-4;
    }
  }
}

  

.search_container__round {
  .pac_create {
    border-radius: 4px;
  } 
}

.search_container__outlined {
  .pac_input,
  .pac_create {
    border: 1px solid qVariables.$grey-5;
  }
}

  
.search_container__normal {

  label {
    font-size: 16px;
    top: 5px;
  }

  .pac {
    &_input {
      border: 1px solid qVariables.$grey-5;
      padding-left: 10px;
      padding-top: 14px;
      height: 56px;
    }
    
    &_label {
      font-size: 16px;
      padding-top: 6px;

      &__focused {
        font-size: 16px
      }
    }

    &_create {
      padding-left: 10px;
      padding-top: 15px;
      height: 56px;
    }
  }
}

.search_container__dense {

  label {
    font-size: 14px;
  }

  .pac {
    &_input {
      border: 1px solid qVariables.$grey-5;
      padding-left: 10px;
      padding-top: 8px;
      height: 40px;
    }

    &_label {
      font-size: 14px

      &__focused {
        font-size: 12px
      }
    }

    &_create {
      border: 1px solid qVariables.$grey-9;
      padding-left: 10px;
      padding-top: 8px;
      height: 40px;
    }
  }
}

.pac-container {
  z-index: 100000 !important;

  .pac {
    &-item {
      width: inherit !important;
      background-color: white;
      height: 50px;
      padding-top: 10px;
      font-weight: 100;

      &:hover {
        background-color: qVariables.$grey-3;
      }
    }

    &-item,
    &-item-query {
      font-size: 15px;
      font-family: 'Quicksand';

      .pac-item-query {
        color: qVariables.$grey-9;
        margin: 0;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);

        .pac-matched {
          color: qVariables.$grey-10;
        }
      }
    }
  }
}
