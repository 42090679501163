
@use "src/css/size.variables.scss" as baseSize;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

@media (min-width: (baseSize.$layout-breakpoint-sm + 1px)) {
  .otp-expiry-justify {
    justify-content: space-between;
  }
}

@media (max-width: baseSize.$layout-breakpoint-sm) {
  .otp-expiry-justify {
    justify-content: flex-end;
  }
}
