@media (min-width: 881px) {
  .create-listing-step-ht-desktop {
    min-height: calc(var(--vh, 1vh) * 100 - 70px - 49px) !important;
  }
  .create-listing-step-ht-mobile {
    min-height: calc(var(--vh, 1vh) * 100 - 51px - 49px) !important;
  }
  .create-listing-step-ht-app {
    min-height: calc(var(--vh, 1vh) * 100 - 51px - 48px) !important;
  }
  .create-listing-step-ht-with-reset-desktop {
    min-height: calc(var(--vh, 1vh) * 100 - 70px - 49px - 16px - 36px) !important;
  }
  .create-listing-step-ht-with-reset-mobile {
    min-height: calc(var(--vh, 1vh) * 100 - 51px - 49px - 52px - 16px - 36px) !important;
  }
  .create-listing-step-ht-with-reset-app {
    min-height: calc(var(--vh, 1vh) * 100 - 51px - 49px - 52px - 16px - 36px - 48px) !important;
  }
}
@media (max-width: 880px) {
  .create-listing-step-ht-desktop {
    min-height: calc(var(--vh, 1vh) * 100 - 70px - 49px - 52px) !important;
  }
  .create-listing-step-ht-mobile {
    min-height: calc(var(--vh, 1vh) * 100 - 51px - 49px - 52px) !important;
  }
  .create-listing-step-ht-app {
    min-height: calc(var(--vh, 1vh) * 100 - 51px - 52px - 48px) !important;
  }
  .create-listing-step-ht-with-reset-desktop {
    min-height: calc(var(--vh, 1vh) * 100 - 70px - 49px - 16px - 36px - 52px) !important;
  }
  .create-listing-step-ht-with-reset-mobile {
    min-height: calc(var(--vh, 1vh) * 100 - 51px - 49px - 52px - 16px - 36px - 52px) !important;
  }
  .create-listing-step-ht-with-reset-app {
    min-height: calc(var(--vh, 1vh) * 100 - 51px - 49px - 52px - 16px - 36px - 52px - 48px) !important;
  }
}