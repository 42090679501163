.lower-brightness {
  filter: brightness(90%);

  &__selected {
    filter: brightness(70%);
  }
}

.img-padding {
  padding-top: 66.66667%;
}

.landing-block,
.landing-block-max {
  position: relative;
}

%cover {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.element {
  input {
    display: none;
  }
  .pic-icon {
    opacity: 0;
    &:hover {
      opacity: 0.7;
    }
  }
}

.search-img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  @extend %cover;
}

.image-fit-abs {
  max-width: 100% !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  @extend %cover;
}

.image-fit {
  @extend .image-fit-abs;
  position: absolute;
}

