
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/colMixin" as colMixin;
@use "src/css/mixins/landingMixin" as landingMixin;
@use "src/css/mixins/paddingMixin" as paddingMixin;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';


.compare-tbl {
  width: 100%;

  th,
  td {
    text-align: center;
  }

  tr.full {
    height: 80px;
  }

  tr.half:nth-child(odd) {
    height: 44px;
  }

  tr.half:nth-child(odd) td {
    @include paddingMixin.padding-mixin('top', 'lg');
  }

  tr.half:nth-child(even) {
    height: 48px;
  }

  tr.half:nth-child(even) td {
    @include paddingMixin.padding-mixin('top', 'sm');
    @include paddingMixin.padding-mixin('bottom', 'lg');
  }

  th:nth-child(1),
  td:nth-child(1) {
    text-align: left;
  }
}

@media (min-width: (baseSize.$layout-breakpoint-xl + 1px)) {
  .sign-up-min-ht {
    min-height: 80px;
  }
}

@media (max-width: baseSize.$layout-breakpoint-xl) {
  .sign-up-min-ht {
    min-height: 64px
  }
}

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .space-type-col {
    @include colMixin.col-script-mixin(3);
  }

  .host-banner-btn {
    @include landingMixin.banner-btn-mixin;
  }

  .contact-section {
    width: calc(50% - 17px);
  }

  .text-center-gt-lg {
    text-align: center;
  }

  .justify-center-gt-lg {
    justify-content: center;
  }

  .compare-tbl {

    th:nth-child(1),
    td:nth-child(1) {
      width: 60%;
    }

    th:nth-child(2),
    td:nth-child(3) {
      width: 20%;
    }
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) {

  .host-banner-btn,
  .contact-section {
    width: 100%;
  }

  .compare-tbl {

    th:nth-child(1),
    td:nth-child(1) {
      width: calc(100% - 136px - 136px)
    }

    th:nth-child(2),
    td:nth-child(2),
    th:nth-child(3),
    td:nth-child(3) {
      width: 136px;
    }
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) and (min-width: (baseSize.$layout-breakpoint-sm + 1px)) {
  .space-type-col {
    @include colMixin.col-script-mixin(6);
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) {
  .space-type-col {
    @include paddingMixin.padding-mixin("x", "sm");
  }
}

@media (max-width: baseSize.$layout-breakpoint-sm) {
  .space-type-col {
    @include colMixin.col-script-mixin(12);
  }
}
