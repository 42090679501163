
@use "src/css/mixins/colMixin" as colMixin;
@use "src/css/mixins/paddingMixin" as paddingMixin;

.mass-apply-min-wh {
  min-width: 132px;
}

@media (min-width: (570px + 1px)) {
  .mass-apply-padding {
    @include paddingMixin.padding-mixin('right', 'sm');
  }
}

@media (max-width: 570px) and (min-width: (360px + 1px)) {
  .mass-apply-col {
    @include colMixin.col-script-mixin(6);
  }

  .mass-apply-col:nth-child(odd) {
    @include paddingMixin.padding-mixin('right', 'sm');
  }

  .mass-apply-col:nth-child(even) {
    @include paddingMixin.padding-mixin('left', 'sm');
  }

  .mass-apply-full {
    @include colMixin.col-script-mixin(12);
    @include paddingMixin.padding-mixin('top', 'md');
  }
}

@media (max-width: 360px) {
  .mass-apply-full,
  .mass-apply-col {
    @include colMixin.col-script-mixin(12);
  }

  .mass-apply-full,
  .mass-apply-col:nth-child(n + 2) {
    @include paddingMixin.padding-mixin('top', 'md');
  }
}
