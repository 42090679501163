
  @use "src/css/size.variables.scss" as baseSize;
  @use "src/css/mixins/paddingMixin" as paddingMixin;
  
  .step-padding > div {
    @include paddingMixin.padding-mixin('bottom', 'lg');
  }
  
  .step-padding:not(:first-child) {
    @include paddingMixin.padding-mixin('top', 'lg');
  }

  .display-show {
    visibility: visible;
    max-height: 10000px;

    transition: visibility .4s, max-height 0s linear;
    transition-delay: 400ms;
  }

  .display-hide {
    visibility: hidden;
    max-height: 0;
  }

  .edit-trans {
    transition: max-height .4s;
  }

  .edit-hidden {
    overflow: hidden;
    line-height: 0em;
    max-height: 0px;
  }
  
  .edit-shown {
    max-height: 10000px;
  }
  