@use "src/css/text.variables.scss" as *;
@use "sass:map";

@mixin custom-text-base-mixin($key) {
  font-size: map.get($custom-text-font-size, $key);
  line-height: map.get($custom-text-line-height, $key);
  letter-spacing: map.get($custom-text-letter-spacing, $key);
}

@mixin custom-text-script-mixin($key, $add-bold: false) {
  
  font-weight: map.get($custom-text-font-weight, $key) !important;
  @include custom-text-base-mixin($key);
  
  @if $add-bold {
    &-bold {
      font-weight: bold !important;
      @include custom-text-base-mixin($key); 
    }
    &-bolder {
      font-weight: bolder !important;
      @include custom-text-base-mixin($key);
    }
  }
}
