@use 'src/css/quasar.variables.scss' as customQVariables;

$custom-color-map: (
  "app": #b8e2f2,
  "coworking": #005672,
  "dark-variant": #242424,
  "label-focused": #24b47e,
  "primary-variant": #61c6d4,
  "primary-variant-light": #258fb0,
  "purchase": #EFFBFF,
  "spaces-prop": #0057B9
);

/* Colors */
@each $ss, $value in $custom-color-map {
  .text-#{$ss} {
    color: $value;
  }

  .bg-#{$ss} {
    background: $value;
  }
}

$custom-text-map: (
  "excel": #1D6F42,
  "pdf": #FF3500,
  "ppt": #D04423,
  "word": #005DA6,

  "header": #565252,
  "link": #008DB9
);

@each $ss, $value in $custom-text-map {
  .text-#{$ss} {
    color: $value;
  }
}

$custom-BG-map: (
  "header": #eee9e9,
  "transparent": transparent
);

@each $ss, $value in $custom-text-map {
  .bg-#{$ss} {
    background: $value;
  }
}

.overlay-generic {
  background-color: rgba(0, 0, 0, 0.2);
}

.overlay-generic:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.links:hover {
  color: customQVariables.$positive;
}

.bg-translucent {

  &__white_dark {
    background: rgba(253, 253, 253, 0.9);
  }

  &__white_light {
    background: rgba(253, 253, 253, 0.8);
  }

  &__white_lightest {
    background: rgba(253, 253, 253, 0.6);
  }

  &__white_zero {
    background: rgba(253, 253, 253, 0.4);
  }

  &__dark_dark {
    background: rgba(0, 0, 0, 0.9);
  }

  &__dark_light {
    background: rgba(0, 0, 0, 0.8);
  }

  &__dark_lightest {
    background: rgba(0, 0, 0, 0.6);
  }

  &__dark_light_45 {
    background: rgba(0, 0, 0, 0.45);
  }

  &__dark_light_3 {
    background: rgba(0, 0, 0, 0.3);
  }

  &__dark_zero {
    background: rgba(0, 0, 0, 0.2);
  }
}

