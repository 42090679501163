
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/colMixin" as colMixin;
@use "src/css/mixins/paddingMixin" as paddingMixin;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.card-desc-scroll {
  height: 212px;
}

.faq-padding {
  @include paddingMixin.padding-mixin('top', 'sm');
}
.faq-padding:last-child {
  @include paddingMixin.padding-mixin('bottom', 'sm');
}

@media (min-width: (baseSize.$layout-breakpoint-xl + 1px)) {
  .info-attachment-col {
    @include colMixin.col-script-mixin(4);
  }

  .info-attachment-col:nth-child(3n + 1) {
    @include paddingMixin.padding-mixin('right', 'sm');
  }

  .info-attachment-col:nth-child(3n + 2) {
    @include paddingMixin.padding-mixin('x', 'xs');
  }

  .info-attachment-col:nth-child(3n + 3) {
    @include paddingMixin.padding-mixin('left', 'sm');
  }

  .info-attachment-col:nth-child(n + 4) {
    @include paddingMixin.padding-mixin('top', 'md');
  }
}

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .related-col {
    @include colMixin.col-script-mixin(6);
  }

  .related-col:nth-child(2n + 1) {
    @include paddingMixin.padding-mixin('right', 'sm');
  }

  .related-col:nth-child(2n + 2) {
    @include paddingMixin.padding-mixin('left', 'sm');
  }

  .related-col:nth-child(n + 3) {
    @include paddingMixin.padding-mixin('top', 'md');
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) {
  .related-col:not(:last-child) {
    @include paddingMixin.padding-mixin('right', 'md');
  }
}


@media (max-width: baseSize.$layout-breakpoint-xl) and (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .info-attachment-col {
    @include colMixin.col-script-mixin(6);
  }

  .info-attachment-col:nth-child(2n + 1) {
    @include paddingMixin.padding-mixin('right', 'sm');
  }

  .info-attachment-col:nth-child(2n + 2) {
    @include paddingMixin.padding-mixin('left', 'sm');
  }

  .info-attachment-col:nth-child(n + 3) {
    @include paddingMixin.padding-mixin('top', 'md');
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) {
  .info-attachment-col {
    @include colMixin.col-script-mixin(12);
  }

  .info-attachment-col:nth-child(n + 1) {
    @include paddingMixin.padding-mixin('top', 'md');
  }
}
