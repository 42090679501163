@use "sass:map";

@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/paddingMixin" as paddingMixin;
@use "src/css/mixins/colMixin" as colMixin;
@use "src/css/mixins/marginMixin" as marginMixin;
@use "src/css/mixins/textMixin" as textMixin;
@use "src/css/mixins/customTextMixin" as customTextMixin;
@use "src/css/mixins/pagePaddingMixin" as pagePaddingMixin;
@use "src/css/mixins/landingMixin" as landingMixin;
@use "src/css/mixins/sideMixin" as sideMixin;

@use 'src/css/quasar.variables.scss' as customQVariables;
@use 'quasar/src/css/variables.sass' as qVariables;

@media (max-width: 1500px) and (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .host-search-card-col {
    @include colMixin.col-script-mixin(4);
  }

  .host-search-card-col:nth-child(3n + 1) {
    padding-right: 5px
  }

  .host-search-card-col:nth-child(3n + 2) {
    @include paddingMixin.padding-mixin('x', 'xs');
  }

  .host-search-card-col:nth-child(3n + 3) {
    padding-left: 5px
  }
}

@media (max-width: baseSize.$layout-breakpoint-xl) {
  @each $padding-side in baseSize.$padding-side-list {
    @each $padding-size in baseSize.$break-point-padding-size-list {
      @if $padding-side == 'a' {
        .p#{str-slice($padding-side, 1, 1)}-#{$padding-size}-lt-xl {
          @include paddingMixin.padding-mixin(null, $padding-size);
        }
      } @else {
        .p#{str-slice($padding-side, 1, 1)}-#{$padding-size}-lt-xl {
          @include paddingMixin.padding-mixin($padding-side, $padding-size);
        }
      }
    }
  }

  .success-title {
    @include textMixin.text-mixin(qVariables.$body1);
  }
}

@media (max-width: baseSize.$layout-breakpoint-xl) and (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .listing-page-padding {
    @include pagePaddingMixin.page-padding-mixin("lg");
  }
}

@media (min-width: (650px + 1px)) {
  .hide-gt-650 {
    display: none;
  }
}

@media (max-width: baseSize.$layout-breakpoint-mlg) {
  .head-block,
  .head-block-new {
    justify-content: center;
    margin-right: 0;
    padding-top: 0;
    position: absolute;
    width: 100%;
  }

  .head-block {
    top: calc(50% - 72px - 32px);

    &-new {
      top: calc(50% - 72px - 32px - 50px); 
    }
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) {
  .reason-col {
    @include colMixin.col-script-mixin(12);
  }

  .editor-pic {
    width: 100vw;
    height: 60vw;
    max-width: 638px;
    max-height: 383px;
  }

  .image-section-width {
    width: 100vw;
  }
}

@media (max-width: baseSize.$layout-breakpoint-xl) and (min-width: (450px + 1px)) {
  .listing-info-col {
    @include colMixin.col-script-mixin(6);
  }

  .listing-info-col:nth-child(2n + 1) {
    @include paddingMixin.padding-mixin('right', 'xs');
  }

  .listing-info-col:nth-child(2n + 2) {
    @include paddingMixin.padding-mixin('left', 'xs');
  }

  .listing-info-col:nth-child(n + 3) {
    @include paddingMixin.padding-mixin('top', 'sm');
  }
}

@media (max-width: baseSize.$layout-breakpoint-xl) and (min-width: (baseSize.$layout-breakpoint-sm + 1px)) {
  .listing-tag-col {
    @include colMixin.col-script-mixin(4);
  }

  .listing-tag-col:nth-child(n + 4) {
    @include paddingMixin.padding-mixin('top', 'md');
  }

  .listing-tag-col:nth-child(3n + 1) {
    padding-right: 10px;
  }

  .listing-tag-col:nth-child(3n + 2) {
    padding-right: 5px;
    padding-left: 5px;
  }

  .listing-tag-col:nth-child(3n + 3) {
    padding-left: 10px;
  }
}

@media (min-width: (660px + 1px)) {
  .mobile-pic {
    &__md {
      height: 300px;
      width: auto;
    }

    &__sm {
      height: 250px;
      width: auto;
    }
  }
}

@media (min-width: (600px + 1px)) {
  .faq-col {
    @include colMixin.col-script-mixin(6);
  }
}

@media (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .col-break-md-2-12 {
    @include colMixin.col-script-mixin(2);
  }

  .landing-desc-col {
    @include colMixin.col-script-mixin(3);
  }

  .col-break-md-4-6-12,
  .col-break-md-4-12 {
    @include colMixin.col-script-mixin(4);
  }

  .col-break-md-6-12,
  .col-break-md-6-4,
  .col-break-md-6-8 {
    @include colMixin.col-script-mixin(6);
  }

  .col-break-md-8-12 {
    @include colMixin.col-script-mixin(8);
  }

  .col-break-md-10-12 {
    @include colMixin.col-script-mixin(10);
  }

  .col-12-gt-md {
    @include colMixin.col-script-mixin(12);
  }

  .landing-card-size {
    width: 932px;
    max-width: calc(85vw);
    max-height: 100%;
  }

  .search-card-margin {
    @include marginMixin.margin-mixin(null, 'sm');
  }

  .pl-smd-gt-md {
    @include paddingMixin.padding-mixin("left", "smd");
  }

  @each $padding-side in baseSize.$padding-side-list {
    @each $padding-size in baseSize.$break-point-padding-size-list {
      @if $padding-side == 'a' {
        .p#{str-slice($padding-side, 1, 1)}-#{$padding-size}-gt-md {
          @include paddingMixin.padding-mixin(null, $padding-size);
        }
      } @else {
        .p#{str-slice($padding-side, 1, 1)}-#{$padding-size}-gt-md {
          @include paddingMixin.padding-mixin($padding-side, $padding-size);
        }
      }
    }
  }

  .try-app-font {
    @include paddingMixin.padding-mixin("top", "md");
  }

  .admin-page-padding {
    @include paddingMixin.padding-mixin("x", "xl");
  }

  .side-gt-md {
    @include sideMixin.side-start-mixin;
  }

  .info-font {
    @include textMixin.text-mixin(qVariables.$h5);
  }

  .listing-tag-txt,
  .map-src-title {
    @include textMixin.text-mixin(qVariables.$body1);
  }

  .nego-font,
  .store-text,
  .try-app-font,
  .fixed-box-content-text,
  .rwd-trans-date,
  .map-src-desc,
  .map-response-text {
    @include textMixin.text-mixin(qVariables.$body2);
  }

  .md-change-font {
    @include textMixin.text-mixin(qVariables.$caption);
  }

  .standard-page-padding {
    @include pagePaddingMixin.page-padding-mixin('md');
  }

  .dashboard-img-padding {
    padding-top: 66.6667%;
  }

  .listing-img-padding {
    padding-top: 44%;
  }

  .fixed-box-content-text {
    font-weight: bold !important;
  }

  .src-vid-btn {
    font-size: 11px;
  }

  .dashboard-img-fixed-ht {
    height: 100%;
  }

  .mobile-pic {
    height: 300px;
    width: auto;
  }

  .map-src-icon {
    font-size: 16px;
  }

  .acc-icon-size {
    font-size: 1.5rem;
  }
  
  .amt-section-wh {
    width: 85px;
  }

  .host-page-msg {
    width: 220px;
  }

  .justify-center-gt-md {
    justify-content: center;
  }
}

@media (min-width: (500px + 1px)) {
  .hide-gt-500 {
    display: none;
  }
}

@media (min-width: (450px + 1px)) {
  .charge-type-search {
    line-height: 0.875rem !important;
    @include textMixin.text-mixin(qVariables.$caption);
  }
}

@media (min-width: (435px + 1px)) {
  .listing-banner-hidden-gt-435 {
    display: none;
  }

  .listing-banner-name-padding {
    padding-bottom: 0 !important;
    @include paddingMixin.padding-mixin("top", "xlg");
  }

  .dashboard-info-timing-desc {
    @include textMixin.text-mixin(qVariables.$caption);
  }

  .video-image {
    height: 88px;
    width: 132px;
  }
}

@media only screen and (min-width: (420px + 1px)) {
  .banner-padding {
    padding-left: 44px;
  }
}

@media (min-width: (400px + 1px)) {
  .hide-gt-400 {
    display: none !important;
  }
}

@media (min-width: (380px + 1px)) {
  .col-break-380-6-12 {
    @include colMixin.col-script-mixin(6);
  }

  .featured-height {
    height: 338px;
  }

  .home-scroll-area {
    height: 335px;
  }

  .map-like-share-icon {
    font-size: 0.8rem;
  }

  .mobile-icon {
    font-size: 1.2rem;
  }

  .mobile-margin {
    margin-right: 16px;
  }  
}

@media (min-width: (baseSize.$layout-breakpoint-sm + 1px)) {
  .col-break-sm-6-12 {
    @include colMixin.col-script-mixin(6);
  }

  .reactive-list-font {
    line-height: 1.5rem !important;
    @include textMixin.text-mixin(qVariables.$h6);
  }

  .respond-time-font,
  .misc-text {
    @include textMixin.text-mixin(qVariables.$body2);
  }

  .message-font,
  .nego-font,
  .used-trans-val {
    @include textMixin.text-mixin(qVariables.$caption);
  }

  @each $padding-side in baseSize.$padding-side-list {
    @each $padding-size in baseSize.$break-point-padding-size-list {
      @if $padding-side == 'a' {
        .p#{str-slice($padding-side, 1, 1)}-#{$padding-size}-gt-sm {
          @include paddingMixin.padding-mixin(null, $padding-size);
        }
      } @else {
        .p#{str-slice($padding-side, 1, 1)}-#{$padding-size}-gt-sm {
          @include paddingMixin.padding-mixin($padding-side, $padding-size);
        }
      }
    }
  }

  .filter-padding {
    @include paddingMixin.padding-mixin('x', 'md');
  }

  .listing-banner-margin {
    @include marginMixin.margin-mixin("x", "smd");
    @include marginMixin.margin-mixin("y", "smd");
  }

  .side-gt-sm {
    @include sideMixin.side-mixin;
  }

  .btn-size {
    font-size: map.get(baseSize.$standard-btn-size-map, "md");
  }

  .justify-center-gt-sm {
    justify-content: center;
  }

  .listing-banner-icon {
    font-size: map.get(baseSize.$icon-size-map, "sm");
    @include marginMixin.margin-mixin("right", "md");
  }

  .landing-banner-btn {
    @include landingMixin.banner-btn-mixin;
  }

  .booking-pic {
    width: 18vh;
    height: auto;
  }
}

@media (min-width: (350px + 1px)) {
  .landing-col-350 {
    @include colMixin.col-script-mixin(6);
  }

  .pl-xs-gt-350 {
    @include paddingMixin.padding-mixin('left', 'xs');
  }

  .pr-xs-gt-350 {
    @include paddingMixin.padding-mixin('right', 'xs');
  }
}

@media (min-width: (340px + 1px)) {
  .shortened-msg-btn {
    max-width: 50%;
  }
}