
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/size.variables.scss" as sizeVar;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.tab-btn {
  .q-btn__content {
    justify-content: flex-start !important;
  }
}

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .edit-listing-ht {
    &-desktop {
      height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht-desktop-login - 80px) !important;
      height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht-desktop-login - 80px - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
    }

    &-mobile {
      height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - 80px) !important;
      height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - 80px - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
    }

    &-app {
      height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - 80px - sizeVar.$app-footer-ht) !important;
      height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - 80px - sizeVar.$app-footer-ht - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
    }
  }
}

