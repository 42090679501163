@use "src/css/size.variables.scss" as sizes;
@use "sass:map";

@mixin margin-mixin($property: null, $type) {
  @if $property == null {
    & {
      margin: map.get(sizes.$padding-size-map, $type);
    }
  } @else if $property == 'x' {
    & {
      margin-left: map.get(sizes.$padding-size-map, $type);
      margin-right: map.get(sizes.$padding-size-map, $type);
    }
  } @else if $property == 'y' {
    & {
      margin-top: map.get(sizes.$padding-size-map, $type);
      margin-bottom: map.get(sizes.$padding-size-map, $type);
    }
  } @else {
    & {
      margin-#{$property}: map.get(sizes.$padding-size-map, $type);
    }
  }
}
