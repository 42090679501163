
@use "src/css/mixins/customTextMixin" as customTextMixin;

.form-pd {
  padding-right: 16px;
  padding-bottom: 16px;
}

@media (min-width: (650px + 1px)) {
  .src-form-ico {
    font-size: 1rem !important;
  }
}

@media (max-width: 650px) {
  .src-form-ico {
    font-size: 0.975rem !important;
  }
}

@media (max-width: 600px) {
  .form-desc-font {
    @include customTextMixin.custom-text-script-mixin("b11");
  }
}
