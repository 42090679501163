@mixin side-mixin {
  align-items: flex-end;
  padding-right: 0;
  padding-left: 16px;
  width: auto;
  min-width: 0;
  max-width: 100%;
}

@mixin side-start-mixin {
  align-items: flex-start;
  padding-right: 16px;
  width: auto;
  min-width: 0;
  max-width: 100%;
}
