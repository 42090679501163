$primary   : #008DB9;
$secondary : #ecbbb6;
$accent    : #ec6d6d;

$dark      : #121212;

$positive  : #5fc4b3;
$negative  : #ec4242;
$info      : #3c2e3d;
$warning   : #fdd835;
