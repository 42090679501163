
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/colMixin" as colMixin;
@use "src/css/mixins/paddingMixin" as paddingMixin;
@use 'quasar/src/css/variables.sass' as qVariables;

.name-max-wh {
  max-width: calc(100% - 90px);
}

.icon-data-wh {
  max-width: calc(100% - 24px);
}

.respond-txt-max-wh {
  width: calc(100% - 18px);
}

@media (max-width: 380px) {
  .desc-padding {
    @include paddingMixin.padding-mixin('top', 'ssm');;
  }
}
