
#generator-freq,
#generator-repeat,
#generator-cap,
#generator-comments {
  ::placeholder {
    font-size: .8rem;
    font-style: italic;
  }
}
