@use "sass:map";
@use "sass:math" as sassMath;

@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/colMixin" as colMixin;
@use "src/css/mixins/paddingMixin" as paddingMixin;
@use "src/css/mixins/marginMixin" as marginMixin;
@use "src/css/mixins/textMixin" as textMixin;
@use "src/css/mixins/customTextMixin" as customTextMixin;
@use "src/css/mixins/sideMixin" as sideMixin;
@use "src/css/mixins/landingMixin" as landingMixin;
@use "src/css/mixins/imageMixin" as imageMixin;
@use "src/css/mixins/pagePaddingMixin" as pagePaddingMixin;

@use 'src/css/quasar.variables.scss' as customQVariables;
@use 'quasar/src/css/variables.sass' as qVariables;

.price-text {
  @include customTextMixin.custom-text-script-mixin('b18', true);
}

.price-text-bold {
  line-height: 1.2rem !important;
}

@media (max-width: baseSize.$layout-breakpoint-lg) {
  .host-comment-reason-col,
  .convince-col,
  .standard-landing-col,
  .general-home-img-col,
  .general-home-tbl-col,
  .col-break-lg-8-12,
  .col-break-lg-7-12,
  .col-break-lg-6-12,
  .col-break-lg-5-12,
  .col-break-lg-4-12,
  .col-12-lt-lg,
  .create-listing-col,
  .full-mobile-width-page-col {
    @include colMixin.col-script-mixin(12);
  }

  .details-dialog-content-col {
    @include colMixin.col-script-mixin(7);
  }

  .col-break-lg-3-6,
  .main-table-col {
    @include colMixin.col-script-mixin(6);
  }

  .src-desc-txt {
    @include textMixin.text-mixin(qVariables.$caption);
  }

  .viewing-filter,
  .search-price-text {
    @include textMixin.text-mixin(qVariables.$body2);
  }

  .search-price-text {
    font-weight: bold !important;
  }

  .search-standard-text {
    @include customTextMixin.custom-text-script-mixin('b8', true);
  }

  .search-desc-text {
    @include customTextMixin.custom-text-script-mixin('b11', true);
  }

  .viewing-title {
    @include textMixin.text-mixin(qVariables.$h6);
  }

  .landing-custom-title {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  .order-first-lt-lg {
    order: -10000;
  }

  .landing-custom-desc {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .banner-btn-font {
    font-size: 12px;
  }

  .list-content,
  .full-width-lt-lg {
    width: 100%;
  }

  .landing-order-first {
    order: -10000;
  }

  .justify-center-lt-lg {
    justify-content: center;
  }

  .txt-center-lt-lg {
    text-align: center;
  }

  .justify-end-lt-lg {
    justify-content: end;
  }

  .text-center-lt-lg {
    text-align: center;
  }

  .home-cat-card-min-ht {
    min-height: 39px;
  }

  .gs-booking-tab-ht {
    height: 40px;
  }

  .arrow-size {
    font-size: 2rem;
  }

  .text-align-host {
    text-align: center;
  }

  .viewing-width {
    width: calc(100% - 2px);
  }

  .layout-padding-desktop {
    padding-top: 70px !important;

    &-search {
      padding-top: 115px !important;
    }
  }

  .layout-padding-mobile {
    padding-top: 51px !important;

    &-search {
      padding-top: 90px !important;
    }
  }

  .app-banner-background {
    min-height: 200px !important;
    @include landingMixin.banner-background-mixin;
  }

  @each $padding-side in baseSize.$padding-side-list {
    @each $padding-size in baseSize.$break-point-padding-size-list {
      @if $padding-side == 'a' {
        .p#{str-slice($padding-side, 1, 1)}-#{$padding-size}-lt-lg {
          @include paddingMixin.padding-mixin(null, $padding-size);
        }
      } @else {
        .p#{str-slice($padding-side, 1, 1)}-#{$padding-size}-lt-lg {
          @include paddingMixin.padding-mixin($padding-side, $padding-size);
        }
      }
    }
  }

  .mt-sm-lt-lg {
    @include marginMixin.margin-mixin("top", "sm");
  }

  .mt-smd-lt-lg {
    @include marginMixin.margin-mixin("top", "smd");
  }

  .mt-md-lt-lg {
    @include marginMixin.margin-mixin("top", "md");
  }

  .pt-xlg-lt-lg {
    @include paddingMixin.padding-mixin('top', 'xlg');
  }

  .pb-xlg-lt-lg {
    @include paddingMixin.padding-mixin('bottom', 'xlg');
  }

  .credit-card-padding {
    @include paddingMixin.padding-mixin("right", "md");
  }

  .info-main-padding {
    @include paddingMixin.padding-mixin("bottom", "md");
  }

  .dashboard-page-padding {
    @include pagePaddingMixin.page-padding-mixin('xxl');
  }

  .side-lt-lg {
    @include sideMixin.side-mixin;
  }

  .my-sm-lt-lg {
    @include marginMixin.margin-mixin("y", "sm");
  }

  .text-lower-lt-lg {
    text-transform: lowercase;
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) and (min-width: (730px + 1px)) {
  .head-block,
  .head-block-new {
    padding-left: 2vw;
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) and (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .standard-page-col,
  .standard-page-col-mobile-only {
    @include colMixin.col-script-mixin(11);
  }

  .listing-tag-img-ht {
    height: calc((((90vw - 24px) / 3) - 16px) / 16 * 9);
  }

  .host-reg-col {
    @include colMixin.col-script-mixin(10);
  }

  .info-col {
    @include colMixin.col-script-mixin(8);
  }

  .landing-title {
    font-size: 1.85rem;
    line-height: 2rem;

    &-getspaces {
      font-size: 3.125rem;
      line-height: 3.25rem;
    }
  }

  .landing-desc {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }

  .landing-credit-desc {
    font-size: 1.125rem;
    line-height: 1.6rem;
  }

  .store-icon {
    width: 75px;
    height: 24px;
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) and (min-width: (baseSize.$layout-breakpoint-sm + 1px)) {
  .line-col {
    @include colMixin.col-script-mixin(6);
  }
}

@media (max-width: 730px) {
  .head-block,
  .head-block-new {
    padding-left: 0;
  }
}

@media (max-width: 715px) {
  .hide-lt-715 {
    display: none;
  }
}

@media (max-width: 660px) {
  .mobile-pic {
    &__md {
      height: 210px;
      width: auto;
    }

    &__sm {
      height: 210px;
      width: auto;
    }
  }
}

@media (max-width: 650px) {
  .hide-lt-650 {
    display: none;
  }
}

@media (max-width: 630px) {
  .hide-lt-630 {
    display: none;
  }
}

@media (max-width: 600px) {
  .faq-col {
    @include colMixin.col-script-mixin(12);
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) {
  .row-lt-md {
    display: flex;
    flex-wrap: wrap;
  }

  .host-reg-col,
  .landing-desc-col,
  .standard-page-col,
  .standard-page-col-mobile-only,
  .col-break-md-2-12,
  .col-break-md-4-12,
  .col-break-md-6-12,
  .col-break-md-8-12,
  .col-break-md-10-12,
  .col-12-lt-md,
  .host-search-card-col,
  .info-col,
  .details-dialog-content-col {
    @include colMixin.col-script-mixin(12);
  }

  .col-6-lt-md {
    @include colMixin.col-script-mixin(6);
  }

  .col-break-md-6-4 {
    @include colMixin.col-script-mixin(4);
  }

  .col-break-md-6-8 {
    @include colMixin.col-script-mixin(8);
  }


  .md-change-font {
    @include customTextMixin.custom-text-script-mixin('b11');
  }

  .map-src-title {
    @include customTextMixin.custom-text-script-mixin('b13', true);
  }

  .nego-font,
  .details-font,
  .caption-lt-md,
  .fixed-box-content-text,
  .store-text,
  .map-src-desc {
    @include textMixin.text-mixin(qVariables.$caption);
  }

  .details-font {
    font-weight: 700;
  }

  .info-font {
    @include textMixin.text-mixin(qVariables.$h6);
  }

  .search-card-margin {
    @include marginMixin.margin-mixin('y', 'sm');
  }

  @each $padding-side in baseSize.$padding-side-list {
    @each $padding-size in baseSize.$break-point-padding-size-list {
      @if $padding-side == 'a' {
        .p#{str-slice($padding-side, 1, 1)}-#{$padding-size}-lt-md {
          @include paddingMixin.padding-mixin(null, $padding-size);
        }
      } @else {
        .p#{str-slice($padding-side, 1, 1)}-#{$padding-size}-lt-md {
          @include paddingMixin.padding-mixin($padding-side, $padding-size);
        }
      }
    }
  }
  
  .admin-page-padding {
    @include paddingMixin.padding-mixin("x", "md");
  }

  .rewards-padding {
    @include paddingMixin.padding-mixin('y', 'sm');
  }

  .standard-page-padding {
    @include pagePaddingMixin.page-padding-mixin('xl');
  }

  .lt-md-change-ordering {
    order: -10000;
  }

  .landing-card-size {
    width: 100%;
  }

  .order-first-lt-md {
    order: -10000;
  }

  .justify-center-lt-md {
    justify-content: center;
  }

  .full-height-lt-md {
    height: 100%;
  }

  .mobile-pic {
    height: 200px;
  }

  .dashboard-img-fixed-ht {
    height: 160px;
  }

  .amt-section-wh {
    width: 75px;
  }

  .map-src-icon {
    font-size: 12px;
  }

  .desc-width {
    max-width: 280px;
  }

  .acc-icon-size {
    font-size: 1.2rem;
  }

  .listing-img-padding {
    padding-top: 66.6667%;
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) and (min-width: (380px + 1px)) {
  .landing-title-getspaces {
    font-size: 2rem;
    line-height: 2.125rem;
  }

  .map-respond-text {
    @include customTextMixin.custom-text-script-mixin('b11');
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) and (min-width: (baseSize.$layout-breakpoint-sm + 1px)) {
  .col-break-md-4-6-12 {
    @include colMixin.col-script-mixin(6);
  }

  .try-app-font {
    @include customTextMixin.custom-text-script-mixin('b11');
  }

  .src-vid-btn {
    font-size: 12px;
  }

  .landing-title {
    font-size: 1.75rem;
    line-height: 1.9rem;
  }

  .landing-desc {
    font-size: 0.85rem;
    line-height: 1.1rem;
  }

  .landing-credit-desc {
    font-size: 0.85rem;
    line-height: 1.25rem;
  }

  .store-icon {
    width: 70px;
    height: 22px;
  }

  .listing-tag-img-ht {
    height: calc((((100vw - 24px) / 3) - 16px) / 16 * 9);
  }

  .confirmation-main-padding {
    @include paddingMixin.padding-mixin('x', 'sm');
  }

  .rwd-trans-date {
    @include textMixin.text-mixin(qVariables.$caption);
  }

  .listing-tag-txt {
    @include textMixin.text-mixin(qVariables.$body2);
  }

  .justify-between-lt-md {
    justify-content: space-between;
  }
}

@media (max-width: 500px) {
  .hide-lt-500 {
    display: none;
  }

  .app-section-width {
    min-width: 150px;
  }
}

@media (max-width: 460px) {
  .hide-lt-460 {
    display: none;
  }
}

@media (max-width: 450px) {
  .listing-info-col {
    @include colMixin.col-script-mixin(12);
    @include paddingMixin.padding-mixin('top', 'sm');
  }

  .charge-type-search {
    line-height: 0.725rem !important;
    @include customTextMixin.custom-text-script-mixin('b10');
  }

  .filters-font {
    @include textMixin.text-mixin(qVariables.$caption);
  }

  .filters-font,
  .charge-type-font {
    font-weight: 700;
  }
}

@media (max-width: 435px) and (min-width: 371px) {
  .video-image {
    height: 88px;
    width: 132px;
  }
}

@media (max-width: 435px) {
  .hide-lt-435 {
    display: none;
  }

  .dashboard-info-timing-desc {
    @include textMixin.text-mixin(qVariables.$caption);
  }

  .listing-banner-name-padding {
    @include paddingMixin.padding-mixin("y", "xlg");
  }
}

@media only screen and (max-width: 420px) and (min-width: (350px + 1px)) {
  .banner-padding {
    @include paddingMixin.padding-mixin("left", "md");
  }
}

@media (max-width: 400px) {
  .hide-lt-400 {
    display: none !important;
  }
}

@media (max-width: 380px) {
  .col-break-380-6-12 {
    @include colMixin.col-script-mixin(12);
  }

  .featured-height {
    height: 351px;
  }

  .pt-xs-lt-380 {
    padding-top: 4px;
  }

  .landing-title-getspaces {
    font-size: 1.8rem;
    line-height: 1.925rem;
  }

  .hide-lt-380 {
    display: none;
  }

  .home-scroll-area {
    height: 359px;
  }

  .map-like-share-icon {
    font-size: 0.6rem;
  }

  .mobile-icon {
    font-size: 1rem;
  }

  .map-respond-text {
    @include customTextMixin.custom-text-script-mixin('b10');
  }

  .mobile-margin {
    margin-right: 8px;
  }
}

@media (max-width: 370px) {
  .video-image {
    height: 74px;
    width: 111px;
  }
}

@media (max-width: baseSize.$layout-breakpoint-sm) {
  .line-col,
  .col-12-lt-sm,
  .col-break-md-4-6-12,
  .col-break-sm-6-12 {
    @include colMixin.col-script-mixin(12);
  }

  .listing-tag-col {
    @include colMixin.col-script-mixin(6);
  }

  .listing-tag-col:nth-child(n + 3) {
    @include paddingMixin.padding-mixin('top', 'md');
  }

  .listing-tag-col:nth-child(2n + 1) {
    @include paddingMixin.padding-mixin('right', 'sm');
  }

  .listing-tag-col:nth-child(2n + 2) {
    @include paddingMixin.padding-mixin('left', 'sm');
  }

  .try-app-font {
    @include customTextMixin.custom-text-script-mixin('b8');
  }

  .used-trans-val {
    @include customTextMixin.custom-text-script-mixin('b10');
  }

  .customer-name-font,
  .message-font,
  .rwd-trans-date {
    @include customTextMixin.custom-text-script-mixin('b11');
  }

  .nego-font {
    @include customTextMixin.custom-text-script-mixin('b12');
  }

  @each $padding-side in baseSize.$padding-side-list {
    @each $padding-size in baseSize.$break-point-padding-size-list {
      @if $padding-side == 'a' {
        .p#{str-slice($padding-side, 1, 1)}-#{$padding-size}-lt-sm {
          @include paddingMixin.padding-mixin(null, $padding-size);
        }
      } @else {
        .p#{str-slice($padding-side, 1, 1)}-#{$padding-size}-lt-sm {
          @include paddingMixin.padding-mixin($padding-side, $padding-size);
        }
      }
    }
  }

  .order-details-horizontal-padding {
    @include paddingMixin.padding-mixin('x', 'xs');
  }
  
  .info-no-padding-lt-sm {
    @include paddingMixin.padding-mixin(null, "none");
  }

  .listing-banner-margin {
    @include marginMixin.margin-mixin("x", "sm");
    @include marginMixin.margin-mixin("y", "sm");
  }

  .reactive-list-font {
    @include textMixin.text-mixin(qVariables.$body1);
  }

  .caption-lt-sm,
  .respond-time-font,
  .listing-tag-txt,
  .misc-text {
    @include textMixin.text-mixin(qVariables.$caption);
  }

  .justify-center-lt-sm {
    justify-content: center;
  }

  .justify-start-lt-sm {
    justify-content: flex-start;
  }

  .btn-size {
    font-size: 12px;
  }

  .src-vid-btn {
    font-size: 10px;
  }

  .booking-pic {
    width: 15vh;
    height: auto;
  }

  .landing-banner-btn {
    width: 100%;
  }

  .listing-tag-img-ht {
    height: calc((((100vw - 24px) / 2) - 16px) / 16 * 9);
  }

  .store-icon {
    width: 60px;
    height: 19px;
  }

  .landing-title {
    font-size: 1.5rem;
    line-height: 1.7rem;
  }

  .landing-desc {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .landing-credit-desc {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  .listing-banner-icon {
    font-size: map.get(baseSize.$icon-size-map, "xxs");
    @include marginMixin.margin-mixin("right", "sm");
  }
}

@media (max-width: 350px) {
  .landing-col-350 {
    @include colMixin.col-script-mixin(12);
  }

  .banner-padding {
    @include paddingMixin.padding-mixin('left', 'sm');
  }
}

@media (max-width: 340px) {
  .hide-lt-340 {
    display: none !important;
  }
}

@media (max-width: 320px) {
  .hide-lt-320 {
    display: none !important;
  }
}
