
@use "src/css/size.variables.scss" as baseSize;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.img-rounded {
  border-radius: 8px 8px 0 0;
}

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .src-rating-size {
    font-size: .875rem;
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) {
  .src-rating-size {
    font-size: .8rem;
  }
}
