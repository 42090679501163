$standard-height-list: (
  10,
  12,
  15,
  16,
  18,
  20,
  21,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  32,
  34,
  35,
  36,
  40,
  42,
  48,
  50,
  51,
  53,
  56,
  58,
  62,
  64,
  68,
  80,
  88,
  90,
  96,
  100,
  108,
  110,
  120,
  150,
  156,
  160,
  165,
  175,
  180,
  200,
  210,
  228,
  240,
  300,
  315,
  350,
  366,
  500,
  520
);

$standard-width-list: (
  15,
  16,
  12,
  20,
  24,
  25,
  30,
  35,
  36,
  38,
  42,
  47,
  48,
  50,
  53,
  55,
  56,
  64,
  66,
  68,
  70,
  75,
  80,
  85,
  90,
  92,
  96,
  97,
  98,
  100,
  104,
  108,
  110,
  118,
  120,
  130,
  140,
  144,
  150,
  152,
  161,
  170,
  175,
  180,
  190,
  200,
  210,
  220,
  225,
  240,
  250,
  270,
  280,
  300,
  310,
  340,
  350,
  360,
  400,
  450,
  464,
  500,
  600,
  700,
  750,
  1000,
  1100
);

$standard-min-width-list: (56, 60, 100, 200, 220, 250, 300, 320, 450);

$standard-max-width-list: (70, 135, 150, 200, 220, 280, 320, 500, 800, 880, 1000, 1100, 1280);

$standard-min-height-list: (0, 21, 48, 56, 60, 64, 72, 84, 100, 126, 134, 500, 640);

$standard-font-size-list: (10, 11, 12, 14, 15, 18, 25, 36, 48, 120);

@each $value in $standard-height-list {
  .st-ht-#{$value} {
    height: #{$value}px !important;
  }
}

@each $value in $standard-width-list {
  .st-wh-#{$value} {
    width: #{$value}px !important;
  }
}

@each $value in $standard-min-width-list {
  .st-min-wh-#{$value} {
    min-width: #{$value}px !important;
  }
}

@each $value in $standard-max-width-list {
  .st-max-wh-#{$value} {
    max-width: #{$value}px !important;
  }
}

@each $value in $standard-min-height-list {
  .st-min-ht-#{$value} {
    min-height: #{$value}px !important;
  }
}

@each $value in $standard-font-size-list {
  .st-font-#{$value} {
    font-size: #{$value}px !important;
  }
}
