@use 'quasar/src/css/variables.sass' as qVariables;
@use 'src/css/quasar.variables.scss' as customQVariables;

.map-respond-text {
  background: customQVariables.$primary;
  color: white;
  font-weight: bold;
  padding: 3px 4px 3px 3px;
  line-height: 15px;
  border-radius: 8px;
}

.respond-text {
  background: rgba(0, 141, 185, 0.8);
  color: white;
  font-weight: bold;
  margin-top: 15px;
  padding: 3px 4px 3px 3px;
  line-height: 15px;
  border-radius: 0 8px 8px 0;
}