
@use "sass:math" as sassMath;

@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/paddingMixin" as paddingMixin;
@use "src/css/mixins/textMixin" as textMixin;

@use 'quasar/src/css/variables.sass' as qVariables;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.lower-brightness-purchase {
  filter: brightness(70%);
}

@media (min-width: (baseSize.$layout-breakpoint-xl + 1px)) {
  .purchase-img-dimensions {
    width: 33.33vw;
    height: 22.22vw;
  }
}

@media (max-width: baseSize.$layout-breakpoint-xl) and (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .purchase-img-dimensions {
    width: 41.67vw;
    height: sassMath.div(41.67vw, 3) * 2;
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) and (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .purchase-img-dimensions {
    width: calc(91.67vw - 8px);
    height: 150px;
    object-fit: cover;
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) {
  .purchase-img-dimensions {
    width: calc(100vw - 32px);
    height: 150px;
    object-fit: cover;
  }
}
