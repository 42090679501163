
@use "src/css/size.variables.scss" as baseSize;
@use 'quasar/src/css/variables' as qVariables;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.body--light {
  .custom-listing-bg-color {
    background-color: qVariables.$grey-4;
  }
}

.body--dark {
  .custom-listing-bg-color {
    background-color: qVariables.$grey-8;
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) {
  .standard-image-padding {
    padding-top: 66.6667%;
  }
}

@media (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .standard-image-padding {
    padding-top: 44.4%;
  }
}
