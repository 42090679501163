.slide-right-enter-active {
  transition: all .5s ease;
}

.slide-right-leave-active {
  transition: all .5s ease;
}

.slide-right-enter, .slide-right-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
