@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/colMixin" as colMixin;
@use "src/css/mixins/paddingMixin" as paddingMixin;

@media (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .uploader-cls {
    @include colMixin.col-script-mixin(4);
  }

  .uploader-cls:nth-child(n + 4) {
    @include paddingMixin.padding-mixin('top', 'md')
  }

  .uploader-cls:nth-child(3n + 1) {
    @include paddingMixin.padding-mixin('right', 'smm')
  }

  .uploader-cls:nth-child(3n + 2) {
    @include paddingMixin.padding-mixin('x', 'xs')
  }

  .uploader-cls:nth-child(3n + 3) {
    @include paddingMixin.padding-mixin('left', 'smm')
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) and (min-width: (baseSize.$layout-breakpoint-sm + 1px)) {
  .uploader-cls {
    @include colMixin.col-script-mixin(6);
  }

  .uploader-cls:nth-child(n + 3) {
    @include paddingMixin.padding-mixin('top', 'md')
  }

  .uploader-cls:nth-child(odd) {
    @include paddingMixin.padding-mixin('right', 'sm')
  }

  .uploader-cls:nth-child(even) {
    @include paddingMixin.padding-mixin('left', 'sm')
  }
}


@media (max-width: baseSize.$layout-breakpoint-sm) {
  .uploader-cls {
    @include colMixin.col-script-mixin(12);
  }

  .uploader-cls:not(:first-child) {
    @include paddingMixin.padding-mixin('top', 'md')
  }
}