
@use 'quasar/src/css/variables.sass' as qVariables;
@use "src/css/size.variables.scss" as baseSize;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

@media (max-width: baseSize.$layout-breakpoint-lg) {
  .border-btm-lt-lg {
    td {
      padding-bottom: 16px;
      border-bottom: 1px solid qVariables.$grey-3;
    }
  }
}

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .border-btm-gt-lg {
    td {
      padding-bottom: 16px;
      border-bottom: 1px solid qVariables.$grey-3;
    }
  }
}
