@use "src/css/size.variables.scss" as sizeVar;

@each $ss, $value in sizeVar.$layout-breakpoint-map {
  @media (max-width: $value) {
    .hide-lt-#{$ss} {
      display: none;
    }

    .invisible-lt-#{$ss} {
      visibility: hidden;
      height: 0;
    }
  }

  @media (min-width: ($value + 1px)) {
    .hide-gt-#{$ss} {
      display: none;
    }

    .invisible-gt-#{$ss} {
      visibility: hidden;
      height: 0;
    }
  }
}
