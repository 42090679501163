
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/colMixin" as colMixin;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

@media (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .share-listing-col {
    @include colMixin.col-script-mixin(3);
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) and (min-width: (baseSize.$layout-breakpoint-sm + 1px)) {
  .share-listing-col {
    @include colMixin.col-script-mixin(4);
  }
}

@media (max-width: baseSize.$layout-breakpoint-sm) {
  .share-listing-col {
    @include colMixin.col-script-mixin(6);
  }
}
