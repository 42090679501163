$custom-icon-font-family: "font-custom" !default;

$custom-font-map: (
  "font-custom-telegram": "\e900",
  "font-custom-facebook": "\f082",
  "font-custom-linkedin": "\f08c",
  "font-custom-instagram": "\f16d",
  "font-custom-apple": "\f179",
  "font-custom-google": "\f1a0",
  "font-custom-file-pdf": "\f1c1",
  "font-custom-file-word": "\f1c2",
  "font-custom-file-excel": "\f1c3",
  "font-custom-file-powerpoint": "\f1c4",
  "font-custom-whatsapp": "\f232",
  "font-custom-percent": "\f295",
  "font-custom-handshake": "\f2b5",
  "font-custom-fan": "\e901",
  "font-custom-laptop": "\e902",
  "font-custom-lightbulb": "\e903",
  "font-custom-shower": "\e904",
);

@font-face {
  font-family: "#{$custom-icon-font-family}";
  src: url("https://dminqct125wnz.cloudfront.net/common/font/CustomIcons/fontcustom_v5.eot");
  src: url("https://dminqct125wnz.cloudfront.net/common/font/CustomIcons/fontcustom_v5.woff2")
      format("woff2"),
    url("https://dminqct125wnz.cloudfront.net/common/font/CustomIcons/fontcustom_v5.woff")
      format("woff"),
    url("https://dminqct125wnz.cloudfront.net/common/font/CustomIcons/fontcustom_v5.eot")
      format("embedded-opentype"),
    url("https://dminqct125wnz.cloudfront.net/common/font/CustomIcons/fontcustom_v5.ttf")
      format("truetype"),
    url("https://dminqct125wnz.cloudfront.net/common/font/CustomIcons/fontcustom_v5.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="font-custom"],
[class*=" font-custom"] {
  font-family: "#{$custom-icon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@each $key, $value in $custom-font-map {
  .#{$key}:before {
    content: $value;
  }
} ;
