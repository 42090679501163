.dialog-wrap {
  margin: 0 auto;
  width: 100%;
}

.dialog-wrap::-webkit-scrollbar,
.dialog-wrap::-webkit-scrollbar-track {
  width: 10px;
}

.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0 auto;
}
