
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/textMixin" as textMixin;

@use 'quasar/src/css/variables.sass' as qVariables;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.abt-img-parent {
  width: 100%;
}

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .abt-img-parent {
    height: calc((100vw / 11) * 4);
    max-height: 50vh;
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) {
  .abt-img-parent {
    height: calc((100vw / 16) * 9);
  }
}

@media (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .abt-title-size {
    @include textMixin.text-mixin(qVariables.$h4)
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) {
  .abt-title-size {
    @include textMixin.text-mixin(qVariables.$h6)
  }
}
