@use "sass:map";

$get-icon-font-family: "get-icon" !default;

$get-font-map: (
  "get-icon-chair": "\e900",
  "get-icon-chat": "\e901",
  "get-icon-chef-hat": "\e902",
  "get-icon-clock-alert-outline": "\e903",
  "get-icon-creditcard": "\e904",
  "get-icon-dollar": "\e905",
  "get-icon-microphone": "\e90e",
  "get-icon-mirror": "\e90f",
  "get-icon-pantry": "\e910",
  "get-icon-pax": "\e911",
  "get-icon-projector": "\e914",
  "get-icon-screen": "\e915",
  "get-icon-size": "\e916",
  "get-icon-snowflake": "\e917",
  "get-icon-snowflake-alt": "\e918",
  "get-icon-sound-system": "\e919",
  "get-icon-space": "\e91a",
  "get-icon-speakers": "\e91b",
  "get-icon-table": "\e91c",
  "get-icon-television": "\e91d",
  "get-icon-toilet": "\e91e",
  "get-icon-whiteboard": "\e91f",
  "get-icon-wifi": "\e920",
  "get-icon-basin": "\e921",
  "get-icon-fridge": "\e922",
  "get-icon-full-mirror": "\e923",
  "get-icon-karaoke": "\e924",
  "get-icon-shoe": "\e925",
  "get-icon-grill": "\e926",
  "get-icon-toolbox": "\e927",
  "get-icon-arrow-rounded": "\e92a",
  "get-icon-emoji-emotions": "\e930",
  "get-icon-event": "\e931",
  "get-icon-info": "\e932",
  "get-icon-person": "\e933"
);

$stacked-map-count: (
  "get-icon-getspaces": 4,
  "get-icon-getproperty": 4,
  "get-icon-profile": 2,
  "get-icon-coworking": 2,
  "get-icon-coworking-dark": 2
);

$stacked-map: (
  "get-icon-getspaces-path1": (
    "map": "\e906",
    "rgb": rgb(253, 215, 2),
  ),
  "get-icon-getspaces-path2": (
    "map": "\e907",
    "rgb": rgb(92, 193, 177),
  ),
  "get-icon-getspaces-path3": (
    "map": "\e908",
    "rgb": rgb(235, 108, 108),
  ),
  "get-icon-getspaces-path4": (
    "map": "\e909",
    "rgb": rgb(0, 86, 114),
  ),
  "get-icon-getproperty-path1": (
    "map": "\e906",
    "rgb": rgb(0, 141, 185),
  ),
  "get-icon-getproperty-path2": (
    "map": "\e907",
    "rgb": rgb(92, 193, 177),
  ),
  "get-icon-getproperty-path3": (
    "map": "\e908",
    "rgb": rgb(0, 87, 185),
  ),
  "get-icon-getproperty-path4": (
    "map": "\e909",
    "rgb": rgb(0, 86, 114),
  ),
  "get-icon-profile-path1": (
    "map": "\e912",
    "rgb": rgb(0, 139, 183),
  ),
  "get-icon-profile-path2": (
    "map": "\e913",
    "rgb": rgb(1, 2, 2),
  ),
  "get-icon-coworking-path1": (
    "map": "\e928",
    "rgb": rgb(250, 213, 0),
  ),
  "get-icon-coworking-path2": (
    "map": "\e929",
    "rgb": rgb(0, 85, 112),
  ),
  "get-icon-coworking-dark-path1": (
    "map": "\e928",
    "rgb": rgb(0, 85, 112),
  ),
  "get-icon-coworking-dark-path2": (
    "map": "\e929",
    "rgb": rgb(250, 213, 0),
  ),
);

@font-face {
  font-family: "#{$get-icon-font-family}";
  src: url("https://dminqct125wnz.cloudfront.net/common/font/GetIcon/get-icon-v5_2.eot");
  src: url("https://dminqct125wnz.cloudfront.net/common/font/GetIcon/get-icon-v5_2.woff2")
      format("woff2"),
    url("https://dminqct125wnz.cloudfront.net/common/font/GetIcon/get-icon-v5_2.woff")
      format("woff"),
    url("https://dminqct125wnz.cloudfront.net/common/font/GetIcon/get-icon-v5_2.eot")
      format("embedded-opentype"),
    url("https://dminqct125wnz.cloudfront.net/common/font/GetIcon/get-icon-v5_2.ttf")
      format("truetype"),
    url("https://dminqct125wnz.cloudfront.net/common/font/GetIcon/get-icon-v5_2.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="get-icon"],
[class*=" get-icon"] {
  font-family: "#{$get-icon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@each $key, $count in $stacked-map-count {
  @for $i from 1 through $count {
    .#{$key} .path#{$i} {
      &:before {
        content: map.get(map.get($stacked-map, "#{$key}-path#{$i}"), "map");
        color: map.get(map.get($stacked-map, "#{$key}-path#{$i}"), "rgb");
      }
    }
  }
}

@each $key, $value in $get-font-map {
  .#{$key}:before {
    content: $value;
  }
} ;
