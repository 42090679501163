@use "sass:math" as sassMath;

@use "src/css/mixins/customTextMixin" as customTextMixin;
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/colMixin" as colMixin;

@use "src/css/mixins/paddingMixin" as paddingMixin;
@use "src/css/mixins/textMixin" as textMixin;
@use "src/css/size.variables.scss" as sizeVar;

@use 'quasar/src/css/variables.sass' as qVariables;

.search-img-cat-offset-mobile {
  top: calc(50px) !important;
}

.see-more-chip {
  margin-left: 4px
}

.app-search-scroll-ht {
  height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - sizeVar.$app-footer-ht);
  height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - sizeVar.$app-footer-ht - env(safe-area-inset-bottom) - env(safe-area-inset-top))
}

.search-map {
  &-desktop,
  &-mobile,
  &-app {
    width: 100vw
  }

  &-side-size {
    &-desktop,
    &-mobile,
    &-app {
      width: 100%
    }
  }
}

.desc-disply-txt-wh {
  max-width: calc(100% - 24px);
}

.rounded-desc {
  border-radius: 0 0 8px 8px;
}

.bordered-desc {
  border: 1px;
  border-style: solid;
  border-color: qVariables.$grey-3 !important;
  border-radius: 0 0 8px 8px !important;
}

.bordered-img {
  border: 1px;
  border-bottom: 0px;
  border-style: solid;
  border-color: qVariables.$grey-3 !important;
  border-radius: 8px 8px 0 0 !important;
}

@media (min-width: (baseSize.$layout-breakpoint-xl + 1px)) {
  .spaces-search-image {
    height: calc((25vw - 16px) * 0.666666667);
  }

  .search-page-size {
    @include paddingMixin.padding-mixin("x", "xl");
  }

  .whatsapp-side-map {
    padding-right: 52px !important;
  }

  .search-cat-img-right-btn {
    right: 48px;
  }

  .search-col {
    @include colMixin.col-script-mixin(3);
  }

  .search-col:nth-child(4n + 1) {
    .search-card-padding {
      padding-right: 10px;
    }
  }

  .search-col:nth-child(4n + 2) {
    .search-card-padding {
      padding-left: 3px;
      padding-right: 7px;
    }
  }

  .search-col:nth-child(4n + 3) {
    .search-card-padding {
      padding-left: 7px;
      padding-right: 3px;
    }
  }


  .search-col:nth-child(4n + 4) {
    .search-card-padding {
      padding-left: 10px;
    }
  }

  .search-col:nth-child(n + 5) {
    .search-card-padding {
      @include paddingMixin.padding-mixin('top', 'md');
    }
  }
}

@media (min-width: (1120px + 1px)) {
  .side-map-card {
    .size-pax-col {
      @include colMixin.col-script-mixin(6);
    }
  }
}

@media (max-width: baseSize.$layout-breakpoint-xl) and (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .search-page-size {
    @include paddingMixin.padding-mixin("x", "lg");
  }

  .whatsapp-side-map {
    padding-right: 28px !important;
  }

  .search-cat-img-right-btn {
    right: 24px;
  }
}

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .see-more-chip {
    &-desktop {
      margin-top: calc(44px + 100px)
    }
  }

  .search-card-map-side-col {
    @include paddingMixin.padding-mixin("right", "md");
    @include colMixin.col-script-mixin(8);
  }

  .search-map-side-col {
    @include colMixin.col-script-mixin(4);
  }
  
  .search-img-cat-offset {
    top: 70px !important;

    &-loggedout {
      top: 50px !important;
    }
    
    .search-card-ls-min-height {
      min-height: calc((var(--vh, 1vh) * 100) - 50px - 100px - 60px - 16px - 30px);
      
      &-desktop {
        min-height: calc((var(--vh, 1vh) * 100) - 69px - 100px - 60px - 16px - 30px);
      }
  
      &-app {
        min-height: calc((var(--vh, 1vh) * 100) - 50px - 100px - 60px - 16px - 30px - 46px);
      }
    }
  
    .search-top-padding {
      @include paddingMixin.padding-mixin("top", "smd");
    }
  }

  .search-top-justify {
    justify-content: space-between;
  }

  .search-map {
    &-desktop {
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login - 44px - 100px);
    }

    &-mobile {
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 44px - 100px);
    }

    &-app {
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 44px - 100px - baseSize.$app-footer-ht);
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 44px - 100px - env(safe-area-inset-bottom) - env(safe-area-inset-top) - baseSize.$app-footer-ht);
    }

    &-side-size {
      &-desktop {
        height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login - 44px - 100px - 16px);
      }
  
      &-mobile {
        height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 44px - 100px - 16px);
      }
  
      &-app {
        height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 44px - 100px - 16px - baseSize.$app-footer-ht);
        height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 44px - 100px - 16px - env(safe-area-inset-bottom) - env(safe-area-inset-top) - baseSize.$app-footer-ht);
      }
    }
  }

  .search-val-scroll {
    &-min-ht-desktop {
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login - 44px - 100px - 16px);
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login - 44px - 100px - 16px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
    }
    
    &-ht-desktop {
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login - 44px - 100px - 16px) !important;
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login - 44px - 100px - 16px - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
    }
  }

  .src-zoom-txt {
    @include textMixin.text-mixin(qVariables.$body2);
  }
}

@media (min-width: (715px + 1px)) {
  .search-side-col {
    @include colMixin.col-script-mixin(4);
  }

  .search-side-col:nth-child(3n + 1) {
    .search-card-padding {
      padding-right: 10px;
    }
  }

  .search-side-col:nth-child(3n + 2) {
    .search-card-padding {
      padding-left: 5px;
      padding-right: 5px;
    }
  }


  .search-side-col:nth-child(3n + 3) {
    .search-card-padding {
      padding-left: 10px;
    }
  }


  .search-side-col:nth-child(n + 4) {
    .search-card-padding {
      @include paddingMixin.padding-mixin('top', 'md');
    }
  }
}

@media (max-width: 1120px) and (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .side-map-card {
    .size-pax-col {
      width: 100% !important;
      @include colMixin.col-script-mixin(12);
    }

    .size-pax-col:nth-child(2) {
      @include paddingMixin.padding-mixin('top', 'xs');
    }
  }
}


@media (max-width: baseSize.$layout-breakpoint-xl) and (min-width: (715px + 1px)) {
  .spaces-search-image {
    height: calc((33.33vw - 16px) * 0.666666667);
  }

  .search-col {
    @include colMixin.col-script-mixin(4);
  }

  .search-col:nth-child(3n + 1) {
    .search-card-padding {
      padding-right: 10px;
    }
  }

  .search-col:nth-child(3n + 2) {
    .search-card-padding {
      padding-left: 5px;
      padding-right: 5px;
    }
  }


  .search-col:nth-child(3n + 3) {
    .search-card-padding {
      padding-left: 10px;
    }
  }


  .search-col:nth-child(n + 4) {
    .search-card-padding {
      @include paddingMixin.padding-mixin('top', 'md');
    }
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) {
  .search-img-cat-offset {
    top: calc(69px) !important;

    &-loggedout {
      top: calc(50px) !important;
    }
  }

  .src-zoom-txt {
    @include customTextMixin.custom-text-script-mixin('b11', true);
  }

  .search-card-map-side-col {
    @include colMixin.col-script-mixin(12);
  }

  .search-map-side-size {
    display: none;
  }

  .search-top-justify {
    justify-content: flex-end;
  }

  .side-map-card {
    .size-pax-col {
      @include colMixin.col-script-mixin(6);
    }
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) and (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .see-more-chip {
    &-desktop {
      margin-top: calc(45px + 100px)
    }
  }


  .search-val-scroll {
    &-min-ht-desktop,
    &-ht-desktop {
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login - 44px - 100px - 25px - 16px);
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login - 44px - 100px - 25px - 16px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
    }
  }

  .search-map {
    &-desktop {
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login - 44px - 100px);
    }

    &-mobile {
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 44px - 100px);
    }

    &-app {
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 44px - 100px - baseSize.$app-footer-ht);
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 44px - 100px - env(safe-area-inset-bottom) - env(safe-area-inset-top) - baseSize.$app-footer-ht);
    }
  }

  .search-card-ls-min-height {
    min-height: calc((var(--vh, 1vh) * 100) - 69px - 48px - 100px - 25px - 30px);
    
    &-desktop {
      min-height: calc((var(--vh, 1vh) * 100) - 50px - 48px - 100px - 25px - 30px);
    }

    &-app {
      min-height: calc((var(--vh, 1vh) * 100) - 50px - 48px - 100px - 25px - 30px - 46px);
    }
  }

  .search-page-size {
    @include paddingMixin.padding-mixin("x", "md");
  }

  .search-cat-img-right-btn {
    right: 16px;
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) and (min-width: (340px + 1px)) {
  .search-top-padding {
    @include paddingMixin.padding-mixin("top", "xs");
  }
}

@media (max-width: 715px) and (min-width: (baseSize.$layout-breakpoint-smd + 1px)) {
  .spaces-search-image {
    height: calc((50vw - 16px) * 0.666666667)
  }

  .search-col,
  .search-side-col {
    @include colMixin.col-script-mixin(6)
  }

  .search-col:nth-child(2n + 1),
  .search-side-col:nth-child(2n + 1) {
    .search-card-padding {
      padding-right: 8px;
    }
  }

  .search-col:nth-child(2n + 2),
  .search-side-col:nth-child(2n + 2) {
    .search-card-padding {
      padding-left: 8px;
    }
  }

  .search-col:nth-child(n + 3),
  .search-side-col:nth-child(n + 3) {
    .search-card-padding {
      @include paddingMixin.padding-mixin('top', 'md');
    }
  }
}

@media (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .see-more-chip {
    &-mobile {
      margin-top: calc(45px  + 100px)
    }
  }

  .search-val-scroll {
    &-min-ht {
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 100px - 44px - 16px);
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 100px - 44px - 16px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
    }

    &-ht {
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 100px - 44px - 16px) !important;
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 100px - 44px - 16px - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
    }

    &-min-ht-app {
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - baseSize.$app-footer-ht - 100px - 44px - 16px);
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - baseSize.$app-footer-ht - 100px - 44px - 16px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
    }

    &-ht-app {
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - baseSize.$app-footer-ht - 100px - 44px - 16px) !important;
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - baseSize.$app-footer-ht - 100px - 44px - 16px - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
    }
  }
}

@media (max-width: baseSize.$layout-breakpoint-md) {
  .see-more-chip {
    &-desktop,
    &-mobile {
      margin-top: calc(45px + 80px)
    }
  }

  .search-val-scroll {
    &-min-ht,
    &-ht {
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 44px - 80px - 25px - 16px);
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 44px - 80px - 25px - 16px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
    }

    &-min-ht-desktop,
    &-ht-desktop {
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login - 44px - 80px - 25px - 16px);
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login - 44px - 80px - 25px - 16px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
    }

    &-min-ht-app,
    &-ht-app {
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - baseSize.$app-footer-ht - 44px - 80px - 25px - 16px);
      min-height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - baseSize.$app-footer-ht - 44px - 80px - 25px - 16px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
    }
  }

  .search-map {
    &-desktop {
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht-desktop-login - 44px - 80px);
    }

    &-mobile {
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 44px - 80px);
    }

    &-app {
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 44px - 80px - baseSize.$app-footer-ht);
      height: calc((var(--vh, 1vh) * 100) - baseSize.$header-ht - 44px - 80px - env(safe-area-inset-bottom) - env(safe-area-inset-top) - baseSize.$app-footer-ht);
    }
  }

  .search-card-ls-min-height {
    min-height: calc((var(--vh, 1vh) * 100) - 69px - 48px - 80px - 25px - 30px);
    
    &-desktop {
      min-height: calc((var(--vh, 1vh) * 100) - 50px - 48px - 80px - 25px - 30px);
    }

    &-app {
      min-height: calc((var(--vh, 1vh) * 100) - 50px - 48px - 80px - 25px - 30px - 46px);
    }
  }

  .search-cat-img-right-btn {
    right: 0;
  }

  .search-page-size {
    @include paddingMixin.padding-mixin("x", "xs");
  }
}

@media (max-width: baseSize.$layout-breakpoint-smd) {
  .spaces-search-image {
    height: calc((100vw - 8px) * 0.666666667);
  }

  .search-col,
  .search-side-col {
    @include colMixin.col-script-mixin(12);
  }

  .search-col:nth-child(n + 2),
  .search-side-col:nth-child(n + 2) {
    .search-card-padding {
      @include paddingMixin.padding-mixin('top', 'md');
    }
  }
}

@media (min-width: (340px + 1px)) {
  .hide-gt-340 {
    display: none !important;
  }
}