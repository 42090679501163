@use "src/css/size.variables.scss" as sizeVar;

.standard-listing-card-ht {
  &-desktop {
    max-height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht-desktop-login - 16px) !important;
    max-height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht-desktop-login - 16px - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
    overflow-y: scroll;
  }

  &-mobile {
    max-height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - 16px) !important;
    max-height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - 16px - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
    overflow-y: scroll;
  }
}

.standard-page-ht {

  &-with-tab {
    &-desktop {
      height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht-desktop-login - 49px) !important;
      height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht-desktop-login - 49px - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
    }

    &-mobile {
      height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - 49px) !important;
      height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - 49px - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
    }

    &-app {
      height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - 49px - sizeVar.$app-footer-ht) !important;
      height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - 49px - sizeVar.$app-footer-ht - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
    }
  }

  &-desktop {
    height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht-desktop-login) !important;
    height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht-desktop-login - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;

    &-wo-header {
      height: calc((var(--vh, 1vh) * 100)) !important;
      height: calc((var(--vh, 1vh) * 100) - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
    }
  }

  &-mobile {
    height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht) !important;
    height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;

    &-ch-notif {
      height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - 53px) !important;
      height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - 53px - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
    }
  }

  &-app {
    height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - sizeVar.$app-footer-ht) !important;
    height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - sizeVar.$app-footer-ht - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;

    &-ch-notif {
      height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - sizeVar.$app-footer-ht - 53px) !important;
      height: calc((var(--vh, 1vh) * 100) - sizeVar.$header-ht - sizeVar.$app-footer-ht - 53px - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
    }
  }
}

.full-page-max-width {
  max-width: 100vw;
}

.ht-auto {
  height: auto;
}

.date-size {
  width: 310px;
}

.landing-block {
  height: calc(75vh - 60px);

  &-max {
    height: 75vh;
  }
}

.featured-height {
  max-width: 90vw;
}

.home-scroll-area {
  max-width: 99vw;
}

.search-pic {
  height: 200px !important;
  width: 300px !important;
}

.search-desc {
  width: 300px !important;
}

.create-tac-ht {
  height: calc((var(--vh, 1vh) * 100) - 300px) !important;
}

.dialog-full-ht {
  height: calc((var(--vh, 1vh) * 100) - 50px);
  height: calc((var(--vh, 1vh) * 100) - 50px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
}

.registration-scroll {
  height: 50vh;
  width: 80vw;
}

.new-store-icon {
  width: 90px;
  height: 29px;
}

.error-width {
  width: 80vw;
}

.info-title {
  font-size: 1.5rem;
}

.host-name-max-width {
  max-width: calc(100% - 40px);

  &-lg {
    max-width: calc(100% - 48px);
  }
}

.dashboard-width {
  max-width: calc(100% - 41px);
}

.requested-by-width {
  max-width: calc(100% - 57px);
}

.title-width {
  max-width: calc(100% - 94px);
}

.notification-area-height {
  height: calc(80vh - 64px);
}

.details-pic {
  width: 100%;
  min-height: 90px;
  max-width: 135px;
  max-height: 90px;

  &-fixed {
    width: 135px;
    height: 90px;
  }
}

%chip-replacement-base {
  border-radius: 25px;
  width: 100px;
}

.chip-replacement {
  height: 25px;
  @extend %chip-replacement-base;

  &__dense {
    height: 15px;
    @extend %chip-replacement-base;
  }
}

.q-scrollarea__content {
  max-width: 100vw !important;
}
