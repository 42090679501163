
@use 'quasar/src/css/variables' as qVariables;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.body--light {
  .custom-listing-text-color {
    color: qVariables.$grey-4;
  }

  .custom-listing-bg-color {
    background-color: qVariables.$grey-4;
  }
}

.body--dark {
  .custom-listing-text-color {
    color: qVariables.$grey-8;
  }

  .custom-listing-bg-color {
    background-color: qVariables.$grey-8;
  }
}

