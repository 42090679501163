.separator, .separator__dark {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before {
  margin-right: .25em;
}

.separator::after {
  margin-left: .25em;
}
