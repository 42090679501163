@use "src/css/size.variables.scss" as baseSize;

@use "src/css/mixins/textMixin" as textMixin;
@use "src/css/mixins/customTextMixin" as customTextMixin;

@use 'quasar/src/css/variables.sass' as qVariables;

@media (min-width: (380px + 1px)) {
  .gen-home-tbl-btn {
    width: 170px;
  }
}

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .gen-home {
    &-title {
      font-size: 32px;
      line-height: 40px;
    }
    
    &-desc {
      font-size: 24px;
      line-height: 32px;
    }

    &-content {
      font-size: 18px;
      line-height: 26px;
    }

    &-tbl {
      &-title {
        font-size: 22px;
        line-height: 24px;
      }

      &-label {
        font-size: 20px;
        line-height: 22px;
      }

      &-subtitle {
        @include textMixin.text-mixin(qVariables.$body1);
      }
    }

    &-tbl-content,
    &-img-content {
      @include customTextMixin.custom-text-script-mixin('b18');
    }

    &-scroll {
      &-app-ht {
        height: calc(100vh - 69px - 32px - 40px - 46px) !important;
        height: calc(100vh - 69px - 32px - 40px - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
      }

      &-ht {
        height: calc(100vh - 69px - 32px - 40px) !important;
      }

      &-img {
        &-ht {
          height: calc(100vh - 69px - 64px - 40px - 8px) !important;
        }

        &-app-ht {
          height: calc(100vh - 69px - 64px - 40px - 8px - 46px) !important;
          height: calc(100vh - 69px - 32px - 40px - 8px - 46px - env(safe-area-inset-bottom) - env(safe-area-inset-top)) !important;
        }
      }

      &-ht,
      &-app-ht {
        max-height: 658px;
      }

      &-img-ht,
      &-img-app-ht {
        max-height: 650px;
      }
    }
  }

  .gen-home-scroll-img-ht {
    min-height: 400px;
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) {
  .gen-home {
    &-title {
      font-size: 24px;
      line-height: 34px;
    }

    &-content {
      font-size: 16px;
      line-height: 24px;
    }

    &-desc {
      font-size: 18px;
      line-height: 26px;
    }

    &-ht,
    &-app-ht {
      max-height: 558px;
    }

    &-img-ht,
    &-img-app-ht {
      max-height: 550px;
    }

    &-scroll-img-ht {
      max-height: 550px;
    }
  }

  .gen-home-scroll-img-ht {
    height: calc((100vw - 32px) / 16 * 9) !important;
    max-height: 275px !important; 
  }
}

@media (max-width: baseSize.$layout-breakpoint-lg) and (min-width: (400px + 1px)) {
  .gen-home {
    &-tbl {
      &-title,
      &-label {
        font-weight: 700 !important;
        @include customTextMixin.custom-text-script-mixin('b18');
      }

      &-subtitle {
        @include textMixin.text-mixin(qVariables.$body2); 
      }
    }

    &-tbl-content,
    &-img-content {
      @include textMixin.text-mixin(qVariables.$body1);
    }
  }
}

@media (max-width: 380px) {
  .gen-home-tbl-btn {
    width: 100%
  }
}

@media (max-width: 400px) and (min-width: 301px) {
  .gen-home {
    &-tbl-title,
    &-tbl-label {
      font-weight: 700;
      @include textMixin.text-mixin(qVariables.$body1);
    }

    &-tbl-content,
    &-img-content,
    &-tbl-subtitle {
      @include textMixin.text-mixin(qVariables.$body2)
    }
  }
}


@media (max-width: 300px) {
  .gen-home {
    &-tbl-title,
    &-tbl-label {
      font-weight: 700 !important;
    }

    &-tbl-content,
    &-tbl-title,
    &-tbl-label,
    &-tbl-subtitle,
    &-img-content {
      @include textMixin.text-mixin(qVariables.$caption);
    }
  }
}