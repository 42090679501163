.slide-down-enter-active {
  transition: all .3s ease;
}

.slide-down-leave-active {
  transition: all .3s ease;
}

.slide-down-enter, .slide-down-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
