.details-padding {
  padding-left: 6px;
}

.admin-page-padding {
  padding-top: 16px;
  padding-bottom: 16px;
}

.img-details-padding {
  padding: 5px;
}
