
@use "src/css/size.variables.scss" as baseSize;
@use "src/css/mixins/paddingMixin" as paddingMixin;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.hide-transition {
  transition: opacity 0.2s,visibility 0.2s,transform 0.2s;
}

.left-btn-bg {
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, .8)
  );
}

.right-btn-bg {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, .8)
  );
}

.default-right-btn-pos {
  right: 0;
}

.cat-img-padding:not(:last-child) {
  @include paddingMixin.padding-mixin('right', 'sm');
}

@media (min-width: (baseSize.$layout-breakpoint-md + 1px)) {
  .search-cat-img-size {
    height: 88px;
    width: 161px;
  }

  .scroll-btn-size {
    font-size: 16px;
  }

  .scroll-btn-ht,
  .search-cat-ht,
  .search-cat-img-wrap {
    height: 100px;
  }
}
@media (max-width: baseSize.$layout-breakpoint-md) {
  .search-cat-img-size {
    height: 68px;
    width: 120px;
  }

  .scroll-btn-size {
    font-size: 14px;
  }

  .scroll-btn-ht,
  .search-cat-ht,
  .search-cat-img-wrap {
    height: 80px;
  }
}
