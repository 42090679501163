@use "src/css/size.variables.scss" as sizeVar;

%sticky-base {
  position: -webkit-sticky;
  position: sticky;
}

.sticky {
  @extend %sticky-base;
  top: 0;
}

.sticky-bottom {
  @extend %sticky-base;
  bottom: 0;
}

.sticky-with-header {
  &-desktop,
  &-mobile {
    position: sticky;
  }

  &-desktop {
    top: calc(sizeVar.$header-ht-desktop-login + 16px) !important;
  }

  &-mobile {
    top: calc(sizeVar.$header-ht + 16px) !important;
  }
}

.sticky-wrap {
  margin: 0 auto;
  width: 100%;
  overflow: scroll;
}

.sticky-wrap::-webkit-scrollbar,
.sticky-wrap::-webkit-scrollbar-track {
  width: 10px;
  height: 100%;
}

.non-transform {
  transform: none !important;
}

.booking-wrap {
  max-width: 100vw;
  margin: 0 auto;
  width: 100%;

  .booking-card {
    float: right;
    width: 40%;
  }

  .booking-card-nonfloat {
    width: 40%;
  }
}
