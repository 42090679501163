@use "src/css/size.variables.scss" as sizes;
@use "sass:map";

@mixin padding-mixin($property: null, $type) {
  @if $property == null {
    & {
      padding: map.get(sizes.$padding-size-map, $type);
    }
  } @if $property == 'x' {
    & {
      padding-left: map.get(sizes.$padding-size-map, $type);
      padding-right: map.get(sizes.$padding-size-map, $type);
    }
  } @if $property == 'y' {
    & {
      padding-top: map.get(sizes.$padding-size-map, $type);
      padding-bottom: map.get(sizes.$padding-size-map, $type);
    }
  } @else {
    & {
      padding-#{$property}: map.get(sizes.$padding-size-map, $type);
    }
  }
}
