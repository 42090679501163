
@use "src/css/size.variables.scss" as baseSize;
@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

@media (min-width: (baseSize.$layout-breakpoint-lg + 1px)) {
  .listing-info-icon {
    i {
      font-size: 36px !important;
    }
  }
}
